import {getHeaderTitle} from '@react-navigation/elements';
import {useContext, useEffect, useState} from 'react';
import Icon from '../components/ui-components/Icon';
import UINavHeader from '../components/ui-components/UINavHeader';
import {UIPressable} from '../components/ui-components/UIPressable';
import {UIScreenContainer} from '../components/ui-components/UIScreenContainer';
import {UIScreenWrapper} from '../components/ui-components/UIScreenWrapper';
import UITextInput from '../components/ui-components/UITextInput';
import {note} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {useTheme} from '../theme/ThemeContext';

const NotesDetailScreen = ({navigation, route}) => {
  const {note: noteItem} = route.params ?? {};
  const {theme} = useTheme();
  const {updateNote} = useContext(DataContext);
  const [noteText, setNoteText] = useState(noteItem[note]);

  const onEditNote = text => {
    setNoteText(text);
  };

  const saveNoteText = async text => {
    try {
      await updateNote({...noteItem, [note]: text});
    } catch (e) {
      console.log(e);
    } finally {
      navigation.pop();
    }
  };

  const goBack = () => navigation.pop();
  const headerLeft = (
    <UIPressable onPress={goBack}>
      <Icon
        name="arrow-left"
        color={theme.colors.text}
        size={18}
        style={{padding: theme.hMargin}}
      />
    </UIPressable>
  );

  useEffect(() => {
    navigation.setOptions({
      header: ({route, options}) => {
        const title = getHeaderTitle(options, route.name);
        const onSave = () => saveNoteText(noteText);
        const headerRight = (
          <UIPressable onPress={onSave}>
            <Icon
              name="check"
              color={theme.colors.primary}
              size={16}
              style={{padding: theme.hMargin}}
            />
          </UIPressable>
        );

        return (
          <UINavHeader
            title={title}
            headerLeft={headerLeft}
            headerRight={headerRight}
          />
        );
      },
    });
  }, [noteText]);

  return (
    <UIScreenWrapper>
      <UIScreenContainer>
        <UITextInput
          value={noteText}
          onChangeText={onEditNote}
          multiline={true}
        />
      </UIScreenContainer>
    </UIScreenWrapper>
  );
};

export default NotesDetailScreen;
