import {readingFontSizes, readingFontStyles, themes} from '../theme/themes';

export const STORAGE_LANGUAGE = 'STORAGE_LANGUAGE';
export const HELLO = 'HELLO';

// DATABASE
export const INTEGER = 'INTEGER';
export const TEXT = 'TEXT';
// Version
export const COMMENT = 'COMMENT';
export const CREATED_AT = 'CREATED_AT';
// Book
export const title = 'title';
export const label = 'label';
export const sequence = 'sequence';
export const testament = 'testament';
export const translated = 'translated';
export const additionalBook = 'additionalBook';
export const audioBookName = 'audioBookName';
// Chapter
export const chapterId = 'chapterId';
export const book_title = 'book_title';
export const isIntroBook = 'isIntroBook';
export const content = 'content';
export const positionInBook = 'positionInBook';
export const audioDownloaded = 'audioDownloaded';
export const book_title_idx = 'book_title_idx';
// Verse
export const id = 'id';
export const canon_order = 'canon_order';
export const chapter = 'chapter';
export const verse_number = 'verse_number';
export const verse_txt = 'verse_txt';
// Footnotes
export const keyword = 'keyword';
export const ref = 'ref';
// Notes
export const chapter_chapterId = 'chapter_chapterId';
export const note = 'note';
export const position = 'position';
export const timestamp = 'timestamp';
// FavoriteQuite
export const quote = 'quote';
// Reading plan
export const readingPlanId = 'readingPlanId';
export const readingName = 'readingName';
export const readingPlanAliasName = 'readingPlanAliasName';
export const readingActive = 'readingActive';
// ReadingPlanProgress
export const readingId = 'readingId';
export const readingEntryId = 'readingEntryId';
export const readingContent = 'readingContent';
export const readingSequence = 'readingSequence';
export const readingCompleted = 'readingCompleted';
export const readingCompletedTimestamp = 'readingCompletedTimestamp';
export const readingEntryCompleted = 'readingEntryCompleted';
export const readingCompensation = 'readingCompensation';
export const readingDateLabel = 'readingDateLabel';
export const fk_reading_plan = 'fk_reading_plan';
// Testament
export const OLD_TESTAMENT_CODE = 0;
export const NEW_TESTAMENT_CODE = 1;
export const ALL_TESTAMENT_CODE = -1;
// Last reading
export const LAST_READING_CHAPTER = 'LAST_READING_CHAPTER';
export const DEFAULT_LAST_READING_CHAPTER = '1000';
// Bookmarked reading
export const BOOKMARK_CHAPTER = 'BOOKMARK_CHAPTER';
// Listening events
export const track = 'track';
export const url = 'url';
export const index = 'index';
// Last listening
export const LAST_LISTENING = 'LAST_LISTENING';
export const LAST_LISTENING_POSITION = 'LAST_LISTENING_POSITION';
export const THEME_SETTINGS = 'THEME_SETTINGS';
export const THEME_FONTS = 'THEME_FONTS';
export const THEME_MODE = 'THEME_MODE';
export const THEME_SIZE = 'THEME_SIZE';
export const DEFAULT_THEME_SETTINGS = {
  [THEME_FONTS]: readingFontStyles.serif,
  [THEME_MODE]: themes.light.name,
  [THEME_SIZE]: readingFontSizes.normal,
};

//export const AUDIO_PARENT_LINK = 'https://biblija.duckdns.org/media';
export const AUDIO_PARENT_LINK = 'https://biblija.live/media';

// App versions
export const APP_VERSION = '5.0.10';
