import {
  CREATED_AT,
  UPDATED_AT,
  book_title,
  id,
  readingId,
  testament,
  title
} from '../constants/stringsAndFields';
import { checkNotEmptyArray, checkValidArray } from '../utils/arrays';
import { BOOK_TABLE } from './book';
import {
  execSqlCmd,
  execSqlCmdThrowable,
  parseSqlCountResults,
  parseSqlExistsResults,
  parseSqlResults,
  runSqlCmd,
  txExec,
} from './sqlOperations';

export const FORCE_DB_CREATE = false;

export const OFFLINE_EVISITOR_FORCE = true;
export const OFFLINE_STORAGE_SUPPORTED = true;
export const OFFLINE_MODE = true;
export const SQL_DEBUG = false;

export const database_name = 'biblijasp.db';
export const database_version = '1.0';
export const database_displayname = 'BiblijaSpDatabase';
export const database_size = 200000;

const TIME_FIELDS = [CREATED_AT, UPDATED_AT];
const UPDATE_TIME_FIELD = [UPDATED_AT];
const EXCLUDE_UPDATE_FIELDS = [id, readingId];
export const SQL_TIME_NOW = 'CURRENT_TIMESTAMP';

export const insertData = async (tx, table, fields, data) => {
  addTimestamps(data);
  const queryKeyNames = fields.join(',');
  const queryKeyValues = fields
    .map(key => `${serializeData(data, key)}`)
    .join(',');
  const addQuery = `INSERT OR REPLACE INTO ${table} (${queryKeyNames}) VALUES (${queryKeyValues});`;
  SQL_DEBUG && console.log('addQuery: ' + addQuery);
  await runSqlCmd(tx, addQuery);
};

export const executeTransaction = async (tx, cmds) => {
  try {
    if (cmds) {
      if (Array.isArray(cmds)) {
        //console.log('executeTransaction array: ' + cmds.length);
        await txExec(tx, cmds);
      } else {
        //console.log('executeTransaction single');
        await txExec(tx, cmds);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteData = async (tx, table, dataId = null) => {
  const whereClause = dataId ? `WHERE ${id} = '${dataId}'` : '';
  const delQuery = `DELETE FROM ${table} ${whereClause};`;
  SQL_DEBUG && console.log('delQuery: ' + delQuery);
  await runSqlCmd(tx, delQuery);
};

export const updateData = async (tx, table, data, idField = id) => {
  //addUpdateTimestamps(data);
  const dataId = data[idField];
  const queryKeyNames = Object.keys(data);
  const filteredKeys = queryKeyNames.filter(
    key => !EXCLUDE_UPDATE_FIELDS.includes(key),
  );
  const setQueryValues = filteredKeys.map(
    key => `${key} = ${serializeData(data, key)}`,
  ); //.slice(0, -1)
  const updateQuery = `UPDATE ${table} SET ${setQueryValues} WHERE ${idField} = '${dataId}';`;
  SQL_DEBUG && console.log('updateQuery: ' + updateQuery);
  await runSqlCmd(tx, updateQuery);
};

export const selectData = async (
  tx,
  table,
  fields,
  filter = id,
  filterData = null,
  orderBy = [],
  distinct = null,
  limit = false,
) => {
  const queryKeyNames = fields.join(',');
  const whereClause = filterData ? `WHERE ${filter} = '${filterData}'` : '';
  const orderByClause = checkNotEmptyArray(orderBy)
    ? `ORDER BY ${orderBy[0]} ${orderBy[1]}`
    : '';
  const distinctClause = distinct ? 'DISTINCT' : '';
  const limitClaues = limit ? limit : '';
  const selectQuery = `SELECT ${distinctClause} ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause} ${limitClaues};`;
  SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
  const dataResults = [];
  const queryResults = await execSqlCmd(tx, selectQuery);
  return parseSqlResults(queryResults, dataResults);
};

export const selectComparedDataBy = async (
  tx,
  table,
  fields,
  filter = id,
  filterData = null,
  filter2 = null,
  filter3 = null,
  orderBy = [],
  distinct = null,
  compareOperator = '>',
  limit = false,
) => {
  const queryKeyNames = fields.join(',');
  const whereClause =
    filter2 && filter3
      ? `WHERE CAST(${filter} AS INTEGER) ${compareOperator} ${filterData} AND ${filter2} = 0 AND ${filter3} = 0`
      : `WHERE CAST(${filter} AS INTEGER) ${compareOperator} ${filterData}`;
  const orderByClause = checkNotEmptyArray(orderBy)
    ? `ORDER BY ${orderBy[0]} ${orderBy[1]}`
    : '';
  const distinctClause = distinct ? 'DISTINCT' : '';
  const limitClaues = limit ? limit : '';
  const selectQuery = `SELECT ${distinctClause} ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause} ${limitClaues};`;
  SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
  const dataResults = [];
  const queryResults = await execSqlCmd(tx, selectQuery);
  return parseSqlResults(queryResults, dataResults);
};

export const selectDataMultiWhere = async (
  tx,
  table,
  fields,
  filter = [],
  filterData = [],
  orderBy = [],
  distinct = null,
) => {
  const queryKeyNames = fields.join(',');
  const whereClause = checkValidArray(filter, filterData)
    ? `WHERE ${filter[0]} = '${filterData[0]}' AND  ${filter[1]} = '${filterData[1]}'`
    : '';
  const orderByClause = checkNotEmptyArray(orderBy)
    ? `ORDER BY ${orderBy[0]} ${orderBy[1]}`
    : '';
  const distinctClause = distinct ? 'DISTINCT' : '';
  const selectQuery = `SELECT ${distinctClause} ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
  SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
  const dataResults = [];
  const queryResults = await execSqlCmd(tx, selectQuery);
  return parseSqlResults(queryResults, dataResults);
};

export const selectDataMultiWhereLike = async (
  tx,
  table,
  fields,
  filter,
  filterData = [],
  orderBy = [],
  distinct = null,
  bookTestament = -1,
) => {
  const queryKeyNames = fields.join(',');
  let whereClause = `WHERE ${table}.${filter} LIKE '%${filterData[0]}%'`;
  let whereAndClauseAnd = '';
  if (filterData.length > 1) {
    for (let i = 1; i < filterData.length; i++) {
      const filterDataItem = filterData[i];
      if (filterDataItem && filterDataItem !== '') {
        whereAndClauseAnd += ` AND ${table}.${filter} LIKE '%${filterDataItem}%'`;
      }
    }
  }
  whereClause = whereClause + whereAndClauseAnd;
  const whereClauseArray = whereClause.split(' ');
  whereClauseArray.shift();
  const whereClauseWithoutPrefix = whereClauseArray.join(' ').trim();

  const orderByClause = checkNotEmptyArray(orderBy)
    ? `ORDER BY ${orderBy[0]} ${orderBy[1]}`
    : '';
  const distinctClause = distinct ? 'DISTINCT' : '';

  let selectQuery = '';
  if (bookTestament === -1) {
    selectQuery = `SELECT ${distinctClause} ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
  } else {
    selectQuery = `
    SELECT ${distinctClause} ${queryKeyNames} FROM ${table} 
    INNER JOIN ${BOOK_TABLE} ON ${BOOK_TABLE}.${title} = ${table}.${book_title}
    WHERE ${BOOK_TABLE}.${testament} LIKE '${bookTestament}' AND
    ${whereClauseWithoutPrefix} ${orderByClause};`;
  }
  SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
  const dataResults = [];
  const queryResults = await execSqlCmd(tx, selectQuery);
  return parseSqlResults(queryResults, dataResults);
};

export const selectDataNotEqual = async (
  tx,
  table,
  fields,
  filter = id,
  filterData = null,
  orderBy = null,
) => {
  const queryKeyNames = fields.join(',');
  const whereClause = filterData ? generateMultiWhere(filter, filterData) : '';
  const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
  const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
  SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
  const dataResults = [];
  const queryResults = await execSqlCmd(tx, selectQuery);
  return parseSqlResults(queryResults, dataResults);
};

const generateMultiWhere = (filterName, filterValues) => {
  let cmd = '';
  if (filterValues?.[0]) {
    cmd = `WHERE ${filterName} <> '${filterValues[0]}' `;
    filterValues.forEach((value, index) => {
      if (index !== 0) {
        cmd = cmd + `AND ${filterName} <> '${value}' `;
      }
    });
  }
  return cmd;
};

export const selectDataMultiFilter = async (
  tx,
  table,
  fields,
  filter1 = null,
  filterData1 = null,
  filter2 = id,
  filterData2 = null,
  orderBy = [],
) => {
  const queryKeyNames = fields.join(',');
  const whereClause =
    filterData1 && filterData2
      ? `WHERE ${filter1} = '${filterData1}' AND ${filter2} = '${filterData2}'`
      : '';
  const orderByClause = checkNotEmptyArray(orderBy)
    ? `ORDER BY ${orderBy[0]} ${orderBy[1]}`
    : '';
  const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
  SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
  const dataResults = [];
  const queryResults = await execSqlCmd(tx, selectQuery);
  return parseSqlResults(queryResults, dataResults);
};

export const checkTableExists = async (tx, table) => {
  const queryResults = await execSqlCmd(
    tx,
    `SELECT name FROM sqlite_master WHERE type='table' AND name='${table}';`,
  );
  return parseSqlExistsResults(queryResults);
};

// other possible check: SELECT COUNT(*) AS CNTREC FROM pragma_table_info('tablename') WHERE name='column_name'
export const checkColumnExist = async (tx, table, column) => {
  try {
    //await execSqlCmdThrowable(tx, `SELECT '${column}' FROM '${table}'`);
    await execSqlCmdThrowable(tx, `SELECT ${column} FROM ${table} LIMIT 1;`);
    return true;
  } catch (e) {
    //console.log(e);
    return false;
  }
};

export const getTableCount = async (tx, table) => {
  const queryResults = await execSqlCmd(tx, `SELECT count(*) FROM ${table}`);
  return parseSqlCountResults(queryResults);
};

export const getTableCountByFilter = async (tx, table, filter) => {
  const queryResults = await execSqlCmd(
    tx,
    `SELECT count(*) FROM ${table} WHERE ${filter};`,
  );
  return parseSqlCountResults(queryResults);
};

export const checkRowDataExists = async (tx, table, filter) => {
  const queryResults = await execSqlCmd(
    tx,
    `SELECT count(*) FROM ${table} WHERE ${filter};`,
  );
  return parseSqlCountResults(queryResults);
};

const addTimestamps = data => {
  for (let timeField of TIME_FIELDS) {
    if (data[timeField] == null) {
      data[timeField] = SQL_TIME_NOW;
    }
  }
};

const addUpdateTimestamps = data => {
  data[UPDATE_TIME_FIELD] = SQL_TIME_NOW;
};

const serializeData = (data, key) => {
  const val = data?.[key] ?? null;
  const out = typeof val === 'object' ? JSON.stringify(val) : val;
  if (typeof out === 'boolean') return out === true ? 1 : 0;
  if (typeof out === 'number') return out; // without ''
  return out === SQL_TIME_NOW ? out : `'${escapeSingleQuote(out)}'`; // timestamp or 'string'
};

const escapeSingleQuote = text =>
  text.includes("'") ? text.replaceAll("'", "''") : text;
