import React from 'react';
import {FlatList} from 'react-native';
import {chapterId} from '../../constants/stringsAndFields';
import ChapterGridItem from './ChapterGridItem';

const ChaptersGridList = ({chapters, onItemPress}) => {
  const renderItem = ({item}) => (
    <ChapterGridItem chapter={item} onItemPress={onItemPress} />
  );

  return (
    <FlatList
      data={chapters}
      renderItem={renderItem}
      keyExtractor={item => item[chapterId]}
      numColumns={7}
      contentContainerStyle={{padding: 15}}
    />
  );
};

export default ChaptersGridList;
