import {timestamp} from '../constants/stringsAndFields';

function getCurrentDayOfYear() {
  const now = new Date();
  const startOfYear = new Date(now.getFullYear(), 0, 0); // January 0 is the day before January 1
  const dayOfYear = Math.floor((now - startOfYear) / (24 * 60 * 60 * 1000));
  return dayOfYear;
}

function convertTimeStampToString(timestamp) {
  try {
    if (!timestamp || isNaN(timestamp)) {
      throw new Error('Invalid timestamp');
    }

    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }

    // Format the date as DD.mm.YYYY. HH:mm
    const formattedDate =
      ('0' + date.getDate()).slice(-2) +
      '.' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '.' +
      date.getFullYear() +
      '. ' +
      ('0' + date.getHours()).slice(-2) +
      ':' +
      ('0' + date.getMinutes()).slice(-2);

    console.log('Formatted Date:', formattedDate);

    return formattedDate;
  } catch (error) {
    console.error('Error converting timestamp to string:', error.message);
    return ' '; // Return an empty string
  }
}


export {getCurrentDayOfYear, convertTimeStampToString};
