import React from 'react';
import {FlatList} from 'react-native';
import ReadingPlanContentItem from './ReadingPlanContentItem';
import {readingSequence} from '../../constants/stringsAndFields';
import UIListEmpty from '../ui-components/UIListEmpty';
import {useTheme} from '../../theme/ThemeContext';

const ReadingPlanContentList = ({readingContentItems, onItemPress}) => {
  const {theme} = useTheme();

  const renderItem = ({item}) => (
    <ReadingPlanContentItem
      readingContentItem={item}
      onItemPress={onItemPress}
    />
  );

  const listEmpty = () => (
    <UIListEmpty icon="exclamation" text="Nema sadržaja" />
  );

  return (
    <FlatList
      data={readingContentItems}
      renderItem={renderItem}
      keyExtractor={item => item[readingSequence]}
      ListEmptyComponent={listEmpty}
      contentContainerStyle={{paddingBottom: theme.navHeight}}
    />
  );
};

export default ReadingPlanContentList;
