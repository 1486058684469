import {StyleSheet, Text, View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import Icon from './Icon';

export const UIListEmpty = ({text, icon}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={styles.container}>
      <Icon name={icon} style={styles.icon}></Icon>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      marginTop: 100,
    },

    icon: {
      fontSize: 30,
      color: theme.colors.icon,
      marginBottom: 30,
    },

    text: {
      color: theme.colors.textLight,
      fontSize: 18,
      fontFamily: theme.fontFamily,
    },
  });
};

export default UIListEmpty;
