import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import NotesList from '../components/note/NotesList';
import DataContext from '../context/dataContext';

const NotesScreen = ({navigation, route}) => {
  const {getNotes, deleteNote} = useContext(DataContext);
  const [notes, setNotes] = useState([]);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      refreshNotes().catch(console.error);
    }
  }, [isFocused]);

  const refreshNotes = async () => {
    const notesDb = await getNotes();
    if (notesDb) {
      setNotes([...notesDb]);
    }
  };

  const onNoteClick = async item => {
    navigation.navigate('Reading', {
      screen: 'NotesDetail',
      params: {note: item},
    });
  };

  const onDeleteNote = async note => {
    await deleteNote(note);
    await refreshNotes();
  };

  return (
    <NotesList
      notes={notes}
      onDeleteNote={onDeleteNote}
      onNoteClick={onNoteClick}
    />
  );
};

export default NotesScreen;
