var timerId;
export const debounceFunction = (func, delay) => {
  clearTimeout(timerId);
  timerId = setTimeout(func, delay);
};

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

let throttleTimer;
export const throttle = (callback, time) => {
  if (throttleTimer) return;
  throttleTimer = true;
  setTimeout(() => {
    callback();
    throttleTimer = false;
  }, time);
};

let throttleTimer2;
export const throttle2 = (callback, time) => {
  if (throttleTimer2) return;
  throttleTimer2 = true;
  setTimeout(() => {
    callback();
    throttleTimer2 = false;
  }, time);
};
