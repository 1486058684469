import React from 'react';
import {Image, Linking, StyleSheet, Text, View} from 'react-native';
import Icon from '../components/ui-components/Icon';
import {UIPressable} from '../components/ui-components/UIPressable';
import {UIScreenScrollWrapper} from '../components/ui-components/UIScreenScrollWrapper';
import {useTheme} from '../theme/ThemeContext';
import globalStyle from '../theme/globalStyle';
import {readingFontSizes, readingFontStyles, themes} from '../theme/themes';
import {APP_VERSION} from '../constants/stringsAndFields';

const SettingsScreen = () => {
  const {
    theme,
    updateTheme,
    fontStyle,
    fontSize,
    updateFontStyle,
    updateFontSize,
  } = useTheme();
  const global = globalStyle(theme);
  const styles = getStyles(theme);

  const openCfcWeb = () => {
    Linking.openURL('https://croatiaforchrist.com/');
  };

  const openCfcMail = () => {
    Linking.openURL('mailto: info@croatiaforchrist.com');
  };

  const openBibleLeagueWeb = () => {
    Linking.openURL('https://bibleleague.org/');
  };

  const openBibleLeagueMail = () => {
    Linking.openURL('mailto: permissions@bibleleague.org');
  };

  const openEcheckinWeb = () => {
    Linking.openURL('https://echeckin.hr/');
  };

  const openEcheckinMail = () => {
    Linking.openURL('mailto: office@echeckin.hr');
  };

  const openPrivacy = () => {
    Linking.openURL('https://croatiaforchrist.com/privacy-policy/');
  };

  const openTerms = () => {
    Linking.openURL('https://croatiaforchrist.com/terms');
  };

  return (
    <UIScreenScrollWrapper>
      <View style={styles.tabContentWrapper}>
        {/* Font */}
        <View>
          <View style={styles.settingsTitleWrapper}>
            <Text style={styles.settingsTitle}>Font Biblije</Text>
          </View>

          <View style={[styles.settingsItem, {paddingRight: 10}]}>
            <Text style={global.textMain}>Familija</Text>
            <View style={global.flexCenter}>
              <UIPressable
                onPress={_ => updateFontStyle(readingFontStyles.sans)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    fontStyle === readingFontStyles.sans &&
                      styles.settingsBtnActive,
                  ]}>
                  Open Sans
                </Text>
              </UIPressable>
              <UIPressable
                onPress={_ => updateFontStyle(readingFontStyles.serif)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    {fontFamily: theme.fontFamilySerif},
                    fontStyle === readingFontStyles.serif && [
                      styles.settingsBtnActive,
                      {fontFamily: theme.fontFamilySerifBold},
                    ],
                  ]}>
                  Noto Serif
                </Text>
              </UIPressable>
            </View>
          </View>

          <View style={[styles.settingsItem, {height: 50, paddingRight: 10}]}>
            <Text style={global.textMain}>Veličina</Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginTop: 2,
              }}>
              <UIPressable
                onPress={_ => updateFontSize(readingFontSizes.small)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    {fontSize: 14, lineHeight: 16},
                    fontSize === readingFontSizes.small &&
                      styles.settingsBtnActive,
                  ]}>
                  A
                </Text>
              </UIPressable>
              <UIPressable
                onPress={_ => updateFontSize(readingFontSizes.normal)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    {fontSize: 16, lineHeight: 16},
                    fontSize === readingFontSizes.normal &&
                      styles.settingsBtnActive,
                  ]}>
                  A
                </Text>
              </UIPressable>
              <UIPressable
                onPress={_ => updateFontSize(readingFontSizes.large)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    {fontSize: 18, lineHeight: 16},
                    fontSize === readingFontSizes.large &&
                      styles.settingsBtnActive,
                  ]}>
                  A
                </Text>
              </UIPressable>
            </View>
          </View>
        </View>

        {/* Tema */}
        <View>
          <View style={styles.settingsTitleWrapper}>
            <Text style={styles.settingsTitle}>Tema</Text>
          </View>
          <View style={[styles.settingsItem, {justifyContent: 'center'}]}>
            <View style={global.flexCenter}>
              <UIPressable onPress={_ => updateTheme(themes.light.name)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    theme.name === themes.light.name &&
                      styles.settingsBtnActive,
                  ]}>
                  Dan
                </Text>
              </UIPressable>
              <UIPressable onPress={_ => updateTheme(themes.dark.name)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    theme.name === themes.dark.name && styles.settingsBtnActive,
                  ]}>
                  Noć
                </Text>
              </UIPressable>
              <UIPressable onPress={_ => updateTheme(themes.sepia.name)}>
                <Text
                  style={[
                    styles.settingsBtnText,
                    theme.name === themes.sepia.name &&
                      styles.settingsBtnActive,
                  ]}>
                  Sepia
                </Text>
              </UIPressable>
            </View>
          </View>
        </View>

        {/* Dokumenti */}
        <View>
          <View style={styles.settingsTitleWrapper}>
            <Text style={styles.settingsTitle}>Dokumenti</Text>
          </View>

          <UIPressable onPress={openTerms}>
            <View style={styles.settingsItem}>
              <Text style={global.link}>Uvjeti korištenja</Text>
              <Icon name="arrow-right" style={styles.arrowIcon} />
            </View>
          </UIPressable>

          <UIPressable onPress={openPrivacy}>
            <View style={styles.settingsItem}>
              <Text style={global.link}>Pravila o privatnosti</Text>
              <Icon name="arrow-right" style={styles.arrowIcon} />
            </View>
          </UIPressable>
        </View>

        {/* O aplikaciji */}
        <View>
          <View style={styles.settingsTitleWrapper}>
            <Text style={styles.settingsTitle}>O aplikaciji</Text>
          </View>

          <View style={[styles.settingsItem, {paddingVertical: 15}]}>
            <Text style={global.textMain}>
              Aplikacija{' '}
              <Text style={global.textBold}>Suvremeni hrvatski prijevod</Text>{' '}
              nastala je od strane misijske službe "Croatia for Christ" uz
              podršku Međunarodne biblijske lige i tehničku realizaciju tvrtke
              eCheckin.
              {'\n'}
              Svrha aplikacije je omogućiti svim vjernicima jednostavan pristup
              Božjoj riječi.
            </Text>
          </View>

          {/* Kontakt */}
          <View>
            <View style={styles.settingsTitleWrapper}>
              <Text style={styles.settingsTitle}>Kontakt</Text>
            </View>
          </View>
          <View style={styles.settingsItem}>
            <View>
              <Image
                source={theme.images.cfcImageSource}
                resizeMode="contain"
                style={{
                  height: 36,
                  width: 160,
                  marginBottom: 15,
                  marginTop: 10,
                }}
              />
              <Text style={[global.textBold, {marginBottom: 5}]}>
                Kristova crkva Varaždin
              </Text>
              <Text style={[global.textMain, {lineHeight: 25}]}>
                Ulica braće Krajanski 14
              </Text>
              <Text style={[global.textMain, {lineHeight: 25}]}>
                42000 Varaždin
              </Text>
              <UIPressable onPress={openCfcWeb}>
                <Text style={[global.link, {lineHeight: 25}]}>
                  www.croatiaforchrist.com
                </Text>
              </UIPressable>
              <UIPressable onPress={openCfcMail}>
                <Text style={[global.link, {lineHeight: 25}]}>
                  info@croatiaforchrist.com
                </Text>
              </UIPressable>
            </View>
          </View>
        </View>

        {/* Podrška */}
        <View>
          <View style={styles.settingsTitleWrapper}>
            <Text style={styles.settingsTitle}>Podrška</Text>
          </View>
          <View style={styles.settingsItem}>
            <View>
              <Image
                source={theme.images.bibleImageSource}
                resizeMode="contain"
                style={{
                  height: 45,
                  width: 145,
                  marginBottom: 15,
                  marginTop: 10,
                }}
              />
              <Text style={[global.textBold, {marginBottom: 5}]}>
                Bible League International
              </Text>
              <Text style={[global.textMain, {lineHeight: 25}]}>
                1 Bible League Plaza Crete
              </Text>
              <Text style={[global.textMain, {lineHeight: 25}]}>
                IL 60417, SAD
              </Text>
              <UIPressable onPress={openBibleLeagueWeb}>
                <Text style={[global.link, {lineHeight: 25}]}>
                  www.bibleleague.org
                </Text>
              </UIPressable>
              <UIPressable onPress={openBibleLeagueMail}>
                <Text style={[global.link, {lineHeight: 25}]}>
                  permissions@bibleleague.org
                </Text>
              </UIPressable>
            </View>
          </View>
        </View>

        {/* Autor */}
        <View>
          <View style={styles.settingsTitleWrapper}>
            <Text style={styles.settingsTitle}>Tehnička realizacija</Text>
          </View>
        </View>
        <View style={styles.settingsItem}>
          <View>
            <Image
              source={theme.images.echeckinImageSource}
              resizeMode="contain"
              style={{height: 29, width: 160, marginBottom: 15, marginTop: 10}}
            />
            <Text style={[global.textBold, {marginBottom: 5}]}>
              eCheckin, vl. Tomislav Krpan
            </Text>
            <Text style={[global.textMain, {lineHeight: 25}]}>
              Opatijska 61
            </Text>
            <Text style={[global.textMain, {lineHeight: 25}]}>
              31000 Osijek
            </Text>
            <UIPressable onPress={openEcheckinWeb}>
              <Text style={[global.link, {lineHeight: 25}]}>
                www.echeckin.hr
              </Text>
            </UIPressable>
            <UIPressable onPress={openEcheckinMail}>
              <Text style={[global.link, {lineHeight: 25}]}>
                office@echeckin.hr
              </Text>
            </UIPressable>
          </View>
        </View>
        <View style={styles.settingsTitleWrapper}>
          <Text style={global.textMain}>Verzija aplikacije: {APP_VERSION}</Text>
        </View>
      </View>
    </UIScreenScrollWrapper>
  );
};

export default SettingsScreen;

const getStyles = theme => {
  return StyleSheet.create({
    tabContentWrapper: {
      paddingBottom: theme.navHeight,
    },

    settingsTitleWrapper: {
      paddingHorizontal: theme.hMargin,
      paddingVertical: 8,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.border,
    },

    settingsTitle: {
      fontFamily: theme.fontFamilyBold,
      fontSize: 13,
      textTransform: 'uppercase',
      color: theme.colors.text,
    },

    settingsItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: theme.hMargin,
      paddingVertical: 10,
      minHeight: 50,
      backgroundColor: theme.colors.navBackground,
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.border,
    },

    settingsBtn: {},

    settingsBtnText: {
      color: theme.colors.text,
      fontFamily: theme.fontFamily,
      padding: 5,
      marginHorizontal: 5,
    },

    settingsBtnActive: {
      color: theme.colors.primary,
      fontFamily: theme.fontFamilyBold,
    },

    arrowIcon: {
      fontSize: 12,
      color: theme.colors.icon,
    },
  });
};
