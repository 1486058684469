import React, {useEffect, useRef} from 'react';
import {FlatList} from 'react-native';
import {readingEntryId} from '../../constants/stringsAndFields';
import {isNotEmpty} from '../../utils/arrays';
import ReadingPlanEntryItem from './ReadingPlanEntryItem';

const ReadingPlanEntryList = ({
  readingPlanEntryItems,
  initialScrollIndex,
  onItemPress,
  selectedItem,
}) => {
  const listRef = useRef(null);

  const renderItem = ({item}) => (
    <ReadingPlanEntryItem
      readingEntry={item}
      onItemPress={onItemPress}
      isSelected={item?.[readingEntryId] === selectedItem?.[readingEntryId]}
      isToday={item?.[readingEntryId] === initialScrollIndex + 1}
    />
  );
  useEffect(() => {
    if (isNotEmpty(readingPlanEntryItems) && listRef?.current) {
      listRef.current.scrollToIndex({
        animated: true,
        index: String(initialScrollIndex),
      });
    }
  }, [readingPlanEntryItems]);

  return (
    <FlatList
      ref={listRef}
      data={readingPlanEntryItems}
      renderItem={renderItem}
      keyExtractor={item => item[readingEntryId]}
      horizontal={true}
      style={{flexGrow: 0}}
      getItemLayout={getItemLayout}
    />
  );
};

const getItemLayout = (data, index) => ({
  length: 75,
  offset: 75 * index,
  index,
});

export default ReadingPlanEntryList;
