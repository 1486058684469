import {useContext, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import UIModal from '../ui-components/UIModal';
import UIButton from '../ui-components/UIButton';
import DataContext from '../../context/dataContext';
import {generateNote} from '../../utils/notes';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import UITextInput from '../ui-components/UITextInput';

const EditNoteModal = ({
  chapter = null,
  currentIndex = 0,
  modalVisible,
  setModalVisible,
}) => {
  const {saveNote, getBookByName, chapters} = useContext(DataContext);
  const [noteText, setNoteText] = useState('');

  const {theme} = useTheme();
  const global = globalStyle(theme);

  const onEditNote = text => {
    setNoteText(text);
  };
  const closeModal = () => {
    setNoteText('');
    setModalVisible(!modalVisible);
  };

  const saveNoteText = async () => {
    try {
      let noteChapter = chapter;
      if (!chapter) {
        const readingChapter = chapters[currentIndex];
        if (readingChapter) {
          noteChapter = readingChapter;
        }
      }
      await generateNote(noteChapter, noteText, getBookByName, saveNote);
    } catch (e) {
      console.log(e);
    } finally {
      closeModal();
    }
  };

  return (
    <UIModal
      visible={modalVisible}
      onClose={() => setModalVisible(!modalVisible)}>
      <Text style={global.textTitle}>{'Dodaj bilješku'}</Text>
      <UITextInput
        onChangeText={onEditNote}
        value={noteText}
        style={styles.input}
        multiline={true}
      />
      <View style={styles.btnWrapper}>
        <UIButton
          title="Odustani"
          type="outline"
          style={styles.btn}
          onPress={closeModal}></UIButton>
        <UIButton
          title="Spremi"
          style={styles.btn}
          onPress={saveNoteText}></UIButton>
      </View>
    </UIModal>
  );
};

const styles = StyleSheet.create({
  btnWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },

  btn: {
    marginHorizontal: 5,
    minWidth: 110,
  },

  input: {
    marginVertical: 20,
  },
});

export default EditNoteModal;
