import React, { useContext, useEffect, useState } from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {verse_number, verse_txt, book_title, positionInBook} from '../../constants/stringsAndFields';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import DataContext from '../../context/dataContext';

const SearchVerseItem = ({verseItem, onVerseClick}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const {getChapterByVerse} = useContext(DataContext);
  const [verseTitle, setVerseTitle] = useState(null);

  useEffect(() => {
    if (verseItem) {
      getChapterByVerse(verseItem).then(chapter => {
        title = `${chapter[book_title]} ${chapter[positionInBook]}:${verseItem[verse_number]}`;
        setVerseTitle(title);
      });
    }
  }, []);

  const handleVerseClick = async _ => {
    onVerseClick(verseItem);
  };

  return (
    <TouchableOpacity style={global.listItem} onPress={handleVerseClick}>
      <View style={global.listItemTextContainer}>
        <Text style={global.textLight}>{verseTitle}</Text>
        <Text style={global.textMain}>{verseItem[verse_txt]}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default SearchVerseItem;
