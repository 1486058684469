import {
  INTEGER,
  TEXT,
  chapterId,
  chapter_chapterId,
  id,
  label,
  note,
  position,
  timestamp
} from '../constants/stringsAndFields';
import { isNotEmpty } from '../utils/arrays';
import { CHAPTER_TABLE } from './chapter';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  updateData,
} from './common';
import { runSqlCmd, exportDatabase } from './sqlOperations';

export const NOTE_TABLE = 'Note';
const keys = [
  id,
  chapterId,
  chapter_chapterId,
  note,
  label,
  position,
  timestamp,
];

// TODO: add index which is used for query
export const createNoteTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${NOTE_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${NOTE_TABLE}(
      ${id} ${TEXT} PRIMARY KEY NOT NULL,
      ${chapterId} ${TEXT},
      ${chapter_chapterId} ${TEXT},
      ${note} ${TEXT},
      ${label} ${TEXT},
      ${position} ${INTEGER},
      ${timestamp} ${INTEGER},
      FOREIGN KEY(${chapter_chapterId}) REFERENCES ${CHAPTER_TABLE}(${chapterId}) ON UPDATE NO ACTION ON DELETE NO ACTION);`,
  );
};

export const reCreateNoteTableAndData = async (tx, cmds) => {
  await createNoteTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertNote = async (tx, note) => {
  await insertData(tx, NOTE_TABLE, keys, note);
  await exportDatabase(tx);
};

export const deleteAllNotes = async tx => {
  await deleteData(tx, NOTE_TABLE);
};

export const deleteNote = async (tx, note) => {
  await deleteData(tx, NOTE_TABLE, note[id]);
  await exportDatabase(tx);
};

export const updateNote = async (tx, note) => {
  await updateData(tx, NOTE_TABLE, note, id);
  await exportDatabase(tx);
};

export const getNotes = async tx => {
  const notes = await selectData(tx, NOTE_TABLE, keys);
  return isNotEmpty(notes) ? notes : [];
};

export const getNote = async (tx, dataId) => {
  const notes = await selectData(
    tx,
    NOTE_TABLE,
    keys,
    id,
    dataId,
    [],
    false,
    'LIMIT 1',
  );
  return isNotEmpty(notes) ? notes[0] : null;
};

export const checkNoteTable = async tx => {
  return await checkTableExists(tx, NOTE_TABLE);
};
