import {
  COMMENT,
  CREATED_AT,
  INTEGER,
  id,
  timestamp,
} from '../constants/stringsAndFields';
import {
  checkColumnExist,
  checkTableExists,
  insertData,
  selectData,
} from './common';
import {FAVORITE_QUOTE_TABLE, checkFavoriteQuoteTable} from './favoriteQuote';
import {runSqlCmd} from './sqlOperations';

export const VERSIONS_TABLE = 'versions';

const keys = [id, COMMENT, CREATED_AT];

export const initVersionsTable = async tx => {
  const exist = await checkVersionsTable(tx);
  if (!exist) {
    await reCreateVersionsTable(tx);
  }
};

export const checkOtherMigrations = async tx => {
  try {
    const migrationTwoApplied = await isMigrationApplied(3, tx);
    if (!migrationTwoApplied) {
      await migration3(tx);
      await insertVersion(tx, {
        id: 3,
        [COMMENT]: 'FAVORITE_QUOTE_TABLE table timestamp field',
        [CREATED_AT]: null,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const reCreateVersionsTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${VERSIONS_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${VERSIONS_TABLE}(
      ${id} INTEGER PRIMARY KEY NOT NULL,
      ${COMMENT} TEXT,
      ${CREATED_AT} TEXT);`,
  );
  const initVersion = {id: 1, [COMMENT]: 'init', [CREATED_AT]: null};
  await insertVersion(tx, initVersion);
};

export const insertVersion = async (tx, version) => {
  await insertData(tx, VERSIONS_TABLE, keys, version);
};

export const checkVersionsTable = async tx => {
  return await checkTableExists(tx, VERSIONS_TABLE);
};

export const isMigrationApplied = async (versionId, tx) => {
  const version = await getVersion(versionId, tx);
  return version && version.length > 0 ? true : false;
};
export const getVersion = async (versionId, tx) => {
  const versions = await selectData(
    tx,
    VERSIONS_TABLE,
    keys,
    id,
    versionId,
    id,
    null,
    'LIMIT 1',
  );
  return versions;
};

const migration3 = async tx => {
  const tableExist = await checkFavoriteQuoteTable(tx);
  if (tableExist) {
    let updatedColumnExists = await checkColumnExist(
      tx,
      FAVORITE_QUOTE_TABLE,
      timestamp,
    );
    if (!updatedColumnExists) {
      await runSqlCmd(
        tx,
        `ALTER TABLE ${FAVORITE_QUOTE_TABLE} ADD COLUMN ${timestamp} ${INTEGER};`,
      );
      updatedColumnExists = await checkColumnExist(
        tx,
        FAVORITE_QUOTE_TABLE,
        timestamp,
      );
      updatedColumnExists && console.log('Migration 2.1 applied successfully');
    }
  }
};
