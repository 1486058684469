import {useContext} from 'react';
import {Text, TouchableOpacity} from 'react-native';
import {
  additionalBook,
  label,
  audioDownloaded,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {isNotEmpty} from '../../utils/arrays';
import Icon from '../ui-components/Icon';

const BookGridItem = ({
  book,
  onBookItemPress,
  onBookSingleChapterPress,
  onSelectMode,
  isSelected,
  onToggleSelect,
}) => {
  const {getChaptersByBookTitle} = useContext(DataContext);
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const isSelectedIcon = onSelectMode && isSelected ? 'check-circle' : 'circle';
  const isSelectedIconColor =
    onSelectMode && isSelected ? theme.colors.primary : theme.colors.icon;
  const isPosibleToDownload = !book[additionalBook] && !book[audioDownloaded];

  const onClickHandler = _ => {
    if (book[additionalBook]) {
      getChaptersByBookTitle(book).then(chapters => {
        if (isNotEmpty(chapters)) {
          onBookSingleChapterPress(book, chapters[0]);
        }
      });
    } else {
      onBookItemPress(book);
    }
  };

  const toggleSelect = () => {
    if (isPosibleToDownload) {
      onToggleSelect(book);
    }
  };

  return (
    <TouchableOpacity
      style={[
        global.gridItem,
        onSelectMode && !isSelected && {borderStyle: 'dashed'},
        isSelected && {borderColor: theme.colors.primary},
        onSelectMode &&
          !isPosibleToDownload && {
            backgroundColor: theme.colors.border,
            borderStyle: 'solid',
          },
      ]}
      onPress={onSelectMode ? toggleSelect : onClickHandler}
      disabled={onSelectMode && !isPosibleToDownload}>
      <Text style={global.gridItemText}>{book[label]}</Text>
      {onSelectMode && isPosibleToDownload && !book?.[audioDownloaded] ? (
        <Icon
          name={isSelectedIcon}
          color={isSelectedIconColor}
          size={11}
          style={[global.gridCheckIcon, {top: 2, right: 2}]}
        />
      ) : null}
      {book?.[audioDownloaded] ? (
        <Icon
          name="volume-1"
          color={onSelectMode ? theme.colors.icon : theme.colors.primary}
          size={11}
          style={[global.gridCheckIcon, {top: 2, right: 2}]}
        />
      ) : null}
    </TouchableOpacity>
  );
};
export default BookGridItem;
