import React from 'react';
import {TouchableOpacity, Text, StyleSheet} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

const UIButton = ({onPress, title, style, titleStyle, type}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  let buttonStyle = styles.defaultButton;
  let textStyle = styles.defaultButtonText;

  if (type === 'outline') {
    buttonStyle = styles.outlineButton;
    textStyle = styles.outlineButtonText;
  } else if (type === 'clear') {
    buttonStyle = styles.clearButton;
    textStyle = styles.clearButtonText;
  }

  return (
    <TouchableOpacity
      style={[styles.button, buttonStyle, style]}
      onPress={onPress}>
      <Text style={[styles.buttonText, textStyle, titleStyle]}>{title}</Text>
    </TouchableOpacity>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    button: {
      minHeight: 38,
      borderRadius: 20,
      borderWidth: 1,
      paddingVertical: 5,
      paddingHorizontal: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },

    defaultButton: {
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.primary,
    },

    outlineButton: {
      backgroundColor: 'transparent',
      borderColor: theme.colors.primary,
    },

    clearButton: {
      backgroundColor: theme.colors.navBackground,
      borderColor: theme.colors.border,
    },

    buttonText: {
      fontSize: 13,
      fontFamily: theme.fontFamilyBold,
      textAlign: 'center',
      textTransform: 'uppercase',
      letterSpacing: 0.2,
    },

    defaultButtonText: {
      color: theme.colors.textInverted,
    },

    outlineButtonText: {
      color: theme.colors.primary,
    },

    clearButtonText: {
      color: theme.colors.text,
    },
  });
};

export default UIButton;
