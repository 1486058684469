import React from 'react';
import {FlatList} from 'react-native';
import {id} from '../../constants/stringsAndFields';
import NoteItem from './NoteItem';
import {UIListEmpty} from '../ui-components/UIListEmpty';
import {useTheme} from '../../theme/ThemeContext';

const NotesList = ({notes, onDeleteNote, onNoteClick}) => {
  const {theme} = useTheme();

  const render = ({item}) => (
    <NoteItem
      noteItem={item}
      onDeleteNote={onDeleteNote}
      onNoteClick={onNoteClick}
    />
  );

  const listEmpty = () => <UIListEmpty icon="note" text="Nema bilješki" />;

  return (
    <FlatList
      data={notes}
      renderItem={render}
      keyExtractor={item => item[id]}
      ListEmptyComponent={listEmpty}
      contentContainerStyle={{paddingBottom: theme.navHeight}}
    />
  );
};

export default NotesList;
