import {v4 as uuidv4} from 'uuid';
import {
  book_title,
  chapterId,
  chapter_chapterId,
  id,
  label,
  note,
  position,
  positionInBook,
  quote,
  timestamp,
} from '../constants/stringsAndFields';

export const generateFavoriteQuote = async (
  chapter,
  text,
  getBookByName,
  saveFunction,
) => {
  const book = await getBookByName(chapter[book_title]);
  if (book) {
    const noteData = {
      [id]: uuidv4(),
      [chapterId]: chapter[chapterId],
      [chapter_chapterId]: chapter[chapterId],
      [label]: book[label],
      [position]: chapter[positionInBook],
      [quote]: text,
      [timestamp]: Date.now(),
    };
    await saveFunction(noteData);
  }
};
export const generateNote = async (
  chapter,
  text,
  getBookByName,
  saveFunction,
) => {
  const book = await getBookByName(chapter[book_title]);
  if (book) {
    const noteData = {
      [id]: uuidv4(),
      [chapterId]: chapter[chapterId],
      [chapter_chapterId]: chapter[chapterId],
      [label]: book[label],
      [position]: chapter[positionInBook],
      [note]: text,
      [timestamp]: Date.now(),
    };
    await saveFunction(noteData);
  }
};
