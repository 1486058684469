import {useContext, useEffect, useState} from 'react';
import VersesGridList from '../components/books/VerseGridList';
import DataContext from '../context/dataContext';
import {extendMissingVerses} from '../utils/arrays';
import {resetStack} from '../utils/navigation';

const VersesScreen = ({navigation, route}) => {
  const {book, chapter} = route.params;
  const {getVersesByChapter} = useContext(DataContext);
  const [verses, setVerses] = useState([]);

  useEffect(() => {
    if (chapter) {
      getVersesByChapter(chapter).then(res => {
        const extendedVerses = extendMissingVerses(res, chapter, book);
        setVerses(extendedVerses);
      });
    }
  }, [chapter]);

  const onVerseItemPress = async selectedItem => {
    resetStack(navigation, -2);
    navigation.navigate('Tabs', {
      screen: 'Biblija',
      params: {
        book: book,
        chapter: chapter,
        verse: selectedItem,
      },
    });
  };

  return <VersesGridList verses={verses} onItemPress={onVerseItemPress} />;
};

export default VersesScreen;
