import {
  INTEGER,
  TEXT,
  readingCompensation,
  readingCompleted,
  readingCompletedTimestamp,
  readingContent,
  readingDateLabel,
  readingEntryCompleted,
  readingEntryId,
  readingId,
  readingPlanId,
  readingSequence,
} from '../constants/stringsAndFields';
import {isNotEmpty, removeDuplicates} from '../utils/arrays';
import {
  SQL_TIME_NOW,
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  selectDataMultiWhere,
  updateData,
} from './common';
import {READING_PLAN_TABLE} from './readingPlan';
import {runSqlCmd} from './sqlOperations';
import {exportDatabase} from './sqlOperations';

export const READING_PLAN_PROGRESS_TABLE = 'ReadingPlanProgress';

const keys = [
  readingId,
  readingPlanId,
  readingEntryId,
  readingContent,
  readingSequence,
  readingCompleted,
  readingCompletedTimestamp,
  readingEntryCompleted,
  readingCompensation,
  readingDateLabel,
];

// TODO: add index which is used for query
export const createReadingPlanProgressTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${READING_PLAN_PROGRESS_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${READING_PLAN_PROGRESS_TABLE}(
      ${readingId} ${TEXT} PRIMARY KEY NOT NULL,
      ${readingPlanId} ${INTEGER},
      ${readingEntryId} ${INTEGER},
      ${readingContent} ${TEXT},
      ${readingSequence} ${INTEGER},
      ${readingCompleted} ${INTEGER} DEFAULT 0,
      ${readingCompletedTimestamp} ${INTEGER},
      ${readingEntryCompleted} ${INTEGER} DEFAULT 0,
      ${readingCompensation} ${INTEGER} DEFAULT 0,
      ${readingDateLabel} ${TEXT},
      FOREIGN KEY(${readingPlanId}) REFERENCES ${READING_PLAN_TABLE}(${readingPlanId}) ON UPDATE NO ACTION ON DELETE NO ACTION);`,
  );
};

export const reCreateReadingPlanProgressTableAndData = async (tx, cmds) => {
  await createReadingPlanProgressTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertReadingPlanProgress = async (tx, readingPlan) => {
  await insertData(tx, READING_PLAN_PROGRESS_TABLE, keys, readingPlan);
};

export const deleteAllReadingPlanProgress = async tx => {
  await deleteData(tx, READING_PLAN_PROGRESS_TABLE);
};

export const deleteReadingPlanProgress = async (tx, readingPlanProgress) => {
  await deleteData(
    tx,
    READING_PLAN_PROGRESS_TABLE,
    readingPlanProgress[readingId],
  );
};

export const updateReadingPlanProgress = async (tx, readingPlanProgress) => {
  await updateData(
    tx,
    READING_PLAN_PROGRESS_TABLE,
    readingPlanProgress,
    readingPlanProgress[readingId],
  );
};

export const getReadingPlanProgresses = async tx => {
  const readingPlanProgresses = await selectData(
    tx,
    READING_PLAN_PROGRESS_TABLE,
    keys,
  );
  return isNotEmpty(readingPlanProgresses) ? readingPlanProgresses : [];
};

export const getReadingPlanProgress = async (tx, dataId) => {
  const readingPlanProgresses = await selectData(
    tx,
    READING_PLAN_PROGRESS_TABLE,
    keys,
    readingId,
    dataId,
    [],
    null,
    'LIMIT 1',
  );
  return isNotEmpty(readingPlanProgresses) ? readingPlanProgresses[0] : null;
};

export const checkReadingPlanProgressTable = async tx => {
  return await checkTableExists(tx, READING_PLAN_PROGRESS_TABLE);
};

export const getDailyReadings = async (tx, readingPlanIdValue) => {
  const dailyReadings = await selectData(
    tx,
    READING_PLAN_PROGRESS_TABLE,
    keys,
    readingPlanId,
    readingPlanIdValue,
    [readingSequence, 'ASC'],
    true,
  );
  const dailyReadingsWithoutDuplicates = removeDuplicates(
    dailyReadings,
    readingEntryId,
  );
  return dailyReadingsWithoutDuplicates;
};

export const getDailyReading = async (
  tx,
  readingPlanIdValue,
  readingEntryIdValue,
) => {
  const dailyReadings = await selectDataMultiWhere(
    tx,
    READING_PLAN_PROGRESS_TABLE,
    keys,
    [readingPlanId, readingEntryId],
    [readingPlanIdValue, readingEntryIdValue],
    [readingSequence, 'ASC'],
    false,
  );
  return dailyReadings;
};

export const resetReadingPlan = async (tx, readingPlanIdValue) => {
  const data = {
    [readingPlanId]: readingPlanIdValue,
    [readingCompleted]: false,
    [readingEntryCompleted]: false,
  };
  await updateData(tx, READING_PLAN_PROGRESS_TABLE, data, readingPlanId);
  await exportDatabase(tx);
};

export const setReadingCompletedAndSave = async (tx, data, isCompleted) => {
  data[readingCompleted] = isCompleted;
  data[readingCompletedTimestamp] = SQL_TIME_NOW;
  await updateData(tx, READING_PLAN_PROGRESS_TABLE, data, readingId);
  await updateAllReadingEntryCompletition(
    tx,
    data[readingPlanId],
    data[readingEntryId],
  );
  await exportDatabase(tx);
};

export const updateAllReadingEntryCompletition = async (
  tx,
  readingPlanIdValue,
  readingEntryIdValue,
) => {
  const readings = await getDailyReading(
    tx,
    readingPlanIdValue,
    readingEntryIdValue,
  );
  // update whole day entry completed if all readings are read
  let entryReadingCompleted = true;

  for (let reading of readings) {
    if (!reading[readingCompleted]) {
      entryReadingCompleted = false;
      break;
    }
  }
  for (let reading of readings) {
    reading[readingEntryCompleted] = entryReadingCompleted ? true : false;
    await updateData(tx, READING_PLAN_PROGRESS_TABLE, reading, readingId);
  }
};
