import {getHeaderTitle} from '@react-navigation/elements';
import {StackActions, useFocusEffect} from '@react-navigation/native';
import {useCallback, useContext, useEffect, useState} from 'react';
import {BackHandler, View} from 'react-native';
import {usePlayer} from '../components/audio-player/PlayerContext';
import {AudioBottomSheet} from '../components/reading/AudioBottomSheet';
import ReadingView from '../components/reading/ReadingView';
import Icon from '../components/ui-components/Icon';
import UINavHeader from '../components/ui-components/UINavHeader';
import {UIPressable} from '../components/ui-components/UIPressable';
import UIScreenReaderWrapper from '../components/ui-components/UIScreenReadingWrapper';
import {
  additionalBook,
  chapterId,
  isIntroBook,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {useTheme} from '../theme/ThemeContext';
import {isNotEmpty} from '../utils/arrays';
import {setChapterTitle} from '../utils/navigation';
import {IS_MOBILE, IS_WEB} from '../utils/platform';
import {
  getBookmarkReading,
  getLastReading,
  resetBookmarkReading,
  saveBookmarkReading,
} from '../utils/storage';
import {UIConnectionStatus} from '../components/ui-components/UIConnectionStatus';

const ReadingScreen = ({route, navigation}) => {
  const {
    getChapterByVerse,
    getIndexByChapter,
    chapters,
    setHideTabBar,
    showConnectionWarning,
  } = useContext(DataContext);
  const {chapter = null, verse = null} = route.params ?? {};
  const [readingVerse, setReadingVerse] = useState(null);
  const [chapterIndex, setChapterIndex] = useState(null);
  const [restoredLastReading, setRestoredLastReading] = useState(false);
  const [bookmarkedReading, setBookmarkedReading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showSoundIcon, setShowSoundIcon] = useState(true);
  const [offset, setOffset] = useState(0);

  const {theme} = useTheme();
  const {isPlayerOpen, openPlayer} = usePlayer();

  const handleOpenPlayer = () => {
    if (IS_WEB) {
      setModalVisible(!modalVisible);
    } else {
      openPlayer();
    }
  };

  const onBookmarkPress = async () => {
    if (bookmarkedReading) {
      setBookmarkedReading(false);
      await resetBookmarkReading();
    } else {
      const currentlyReading = await getLastReading();
      setBookmarkedReading(true);
      await saveBookmarkReading(currentlyReading);
    }
  };

  // First clean start
  useEffect(() => {
    if (!chapter && !verse && isNotEmpty(chapters)) {
      getBookmarkReading().then(bookmark => {
        getLastReading().then(lastReadingChapterId => {
          const openChapterId = bookmark ? bookmark : lastReadingChapterId;
          if (bookmark) {
            //if bookmark is not null set bookmarkedReading on true (default state is false)
            setBookmarkedReading(true);
          }
          setRestoredLastReading(true);
          const index = getIndexByChapter(openChapterId);
          setChapterTitle(index, chapters, navigation);
          setChapterIndex(index);
          const readingChapter = chapters[index];
          setShowSoundIcon(!readingChapter[additionalBook]);
        });
      });
    }
  }, [chapter, chapters]);

  // When navigated with Verse
  useEffect(() => {
    if (verse) {
      getChapterByVerse(verse).then(chapterVal => {
        setReadingVerse(verse);
        const index = getIndexByChapter(chapterVal[chapterId]);
        setChapterIndex(index);
        setShowSoundIcon(!chapterVal[additionalBook]);
      });
    }
  }, [verse]);

  // When navigated only by chapter
  useEffect(() => {
    if (chapter && !verse) {
      const index = getIndexByChapter(chapter[chapterId]);
      setChapterIndex(index);
      const readingChapter = chapters[index];
      setShowSoundIcon(!readingChapter[additionalBook]);
    }
  }, [chapter]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        if (navigation.canGoBack()) {
          navigation.dispatch(StackActions.popToTop());
        }
        BackHandler.exitApp();
      };
      const subscription = BackHandler.addEventListener(
        'hardwareBackPress',
        onBackPress,
      );
      return () => subscription.remove();
    }, [navigation]),
  );

  useEffect(() => {
    const headerRightButtons = (
      <View style={{flexDirection: 'row'}}>
        {showSoundIcon && IS_MOBILE && (
          <UIPressable onPress={openPlayer} disabled={isPlayerOpen}>
            <Icon
              name="volume-1"
              color={
                !isPlayerOpen ? theme.colors.tabIcon : theme.colors.primary
              }
              size={20}
              style={{padding: theme.hMargin, paddingRight: 5}}
            />
          </UIPressable>
        )}
        {showSoundIcon && IS_WEB && (
          <UIPressable onPress={handleOpenPlayer}>
            <Icon
              name="volume-1"
              color={theme.colors.tabIcon}
              size={20}
              style={{padding: theme.hMargin, paddingRight: 5}}
            />
          </UIPressable>
        )}
        <UIPressable
          onPress={() => navigation.navigate('Reading', {screen: 'Search'})}>
          <Icon
            name="magnifier"
            color={theme.colors.tabIcon}
            size={19}
            style={{padding: theme.hMargin}}
          />
        </UIPressable>
      </View>
    );

    const openBookChooser = () =>
      navigation.navigate('Reading', {screen: 'Books'});

    const headerLeftButtons = (
      <View style={{flexDirection: 'row'}}>
        <UIPressable onPress={openBookChooser}>
          <Icon
            name="bible"
            color={theme.colors.primary}
            size={20}
            style={{padding: theme.hMargin}}
          />
        </UIPressable>
        <UIPressable onPress={onBookmarkPress}>
          <Icon
            name="bookmark"
            color={
              bookmarkedReading ? theme.colors.primary : theme.colors.tabIcon
            }
            size={20}
            style={{padding: theme.hMargin}}
          />
        </UIPressable>
      </View>
    );

    navigation.setOptions({
      header: ({navigation, route, options}) => {
        const title = getHeaderTitle(options, route.name);
        return (
          <UINavHeader
            title={title}
            headerLeft={headerLeftButtons}
            headerRight={headerRightButtons}
          />
        );
      },
    });
  }, [showSoundIcon, isPlayerOpen, bookmarkedReading, theme]);

  const tabDefaultStyle = {
    tabBarStyle: {
      backgroundColor: theme.colors.navBackground,
      borderTopWidth: 0,
      height: theme.navHeight,
      paddingBottom: 5,
      paddingTop: 3,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.4,
      shadowOffset: {width: 0, height: -1},
      shadowRadius: 10,
      elevation: 5,
    },
  };

  const scrollCallback = e => {
    if (IS_WEB && e) {
      const currentOffset = e.nativeEvent.contentOffset.y;
      const direction = currentOffset > offset ? 'down' : 'up';
      if (direction === 'down') {
        navigation.setOptions(tabHiddenStyle);
        setHideTabBar(true);
      } else {
        navigation.setOptions(tabDefaultStyle);
        setHideTabBar(false);
      }
      setOffset(currentOffset);
    }
  };

  return (
    <UIScreenReaderWrapper scrollCallback={scrollCallback}>
      {showConnectionWarning && <UIConnectionStatus />}
      <ReadingView
        verse={readingVerse}
        setReadingVerse={setReadingVerse}
        chapterIndex={chapterIndex}
        navigation={navigation}
        restoredLastReading={restoredLastReading}
        setRestoredLastReading={setRestoredLastReading}
        setShowSoundIcon={setShowSoundIcon}
        setBookmarkedReading={setBookmarkedReading}
      />
      {IS_WEB && (
        <AudioBottomSheet
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      )}
    </UIScreenReaderWrapper>
  );
};

const tabHiddenStyle = {
  tabBarStyle: {display: 'none'},
};

export default ReadingScreen;
