import {COMMENT, CREATED_AT} from '../constants/stringsAndFields';
import {chunks} from '../utils/arrays';
import {BOOK_TABLE, getBooks, reCreateBookTableAndData} from './book';
import {
  CHAPTER_TABLE,
  getChapters,
  reCreateChapterTableAndData,
} from './chapter';
import {createFavoriteQuoteTable} from './favoriteQuote';
import {
  FOOTNOTE_TABLE,
  getFootnotes,
  reCreateFootnoteTableAndData,
} from './footnote';
import {createNoteTable} from './note';
import {
  READING_PLAN_TABLE,
  reCreateReadingPlanTableAndData,
} from './readingPlan';
import {
  READING_PLAN_PROGRESS_TABLE,
  getReadingPlanProgresses,
  reCreateReadingPlanProgressTableAndData,
} from './readingPlanProgress';
import {VERSE_TABLE, getVerses, reCreateVerseTableAndData} from './verse';
import {insertVersion} from './versions';

export const initTablesAndData = async (db, dbContent) => {
  await reCreateBookTableAndData(db, dbContent[BOOK_TABLE]);
  //const books = await getBooks(db);
  //console.log(books);
  await reCreateChapterTableAndData(db, dbContent[CHAPTER_TABLE]);
  //const chapters = await getChapters(db);
  //console.log(chapters);
  await reCreateFootnoteTableAndData(db, dbContent[FOOTNOTE_TABLE]);
  //const footnotes = await getFootnotes(db);
  //console.log(footnotes);
  await reCreateVerseTableAndData(db, dbContent[VERSE_TABLE]);
  //const verses = await getVerses(db);
  //console.log(verses);
  await createNoteTable(db);
  await createFavoriteQuoteTable(db);
  await reCreateReadingPlanTableAndData(db, dbContent[READING_PLAN_TABLE]);
  await reCreateReadingPlanProgressTableAndData(
    db,
    dbContent[READING_PLAN_PROGRESS_TABLE],
  );
  //const readingPlanProgresses = await getReadingPlanProgresses(db);
  //console.log(readingPlanProgresses);
  await insertVersion(db, {
    id: 2,
    [COMMENT]: 'init data loaded',
    [CREATED_AT]: null,
  });
};

export const parseTextContent = content => {
  try {
    if (content) {
      const lines = content.split(/\n/);
      if (lines) {
        const out = {};
        const bookLines = lines.filter(line => line.includes('`Book`'));
        const bookLinesChunks = chunks(bookLines, 30);
        out[BOOK_TABLE] = bookLinesChunks;
        // large data
        const chapterLines = lines.filter(line => line.includes('`Chapter`'));
        const chapterLinesChunks = chunks(chapterLines, 100);
        out[CHAPTER_TABLE] = chapterLinesChunks;
        const footnotesLines = lines.filter(line =>
          line.includes('`Footnote`'),
        );
        const footnotesLinesChunks = chunks(footnotesLines, 100);
        out[FOOTNOTE_TABLE] = footnotesLinesChunks;
        const versesLines = lines.filter(line => line.includes('`Verse`'));
        const versesLinesChunks = chunks(versesLines, 100);
        out[VERSE_TABLE] = versesLinesChunks;
        // Reading plans
        const readingPlanLines = lines.filter(line =>
          line.includes(`'ReadingPlan'`),
        );
        const readingPlanChunks = chunks(readingPlanLines, 1);
        out[READING_PLAN_TABLE] = readingPlanChunks;
        const readingPlanProgressLines = lines.filter(line =>
          line.includes(`'ReadingPlanProgress'`),
        );
        const readingPlanProgressLinesChunks = chunks(
          readingPlanProgressLines,
          100,
        );
        out[READING_PLAN_PROGRESS_TABLE] = readingPlanProgressLinesChunks;
        return out;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};
