import {
  INTEGER,
  TEXT,
  readingActive,
  readingName,
  readingPlanAliasName,
  readingPlanId
} from '../constants/stringsAndFields';
import { isNotEmpty } from '../utils/arrays';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  updateData,
} from './common';
import { runSqlCmd } from './sqlOperations';

export const READING_PLAN_TABLE = 'ReadingPlan';

const keys = [readingPlanId, readingName, readingPlanAliasName, readingActive];

// TODO: add index which is used for query
export const createReadingPlanTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${READING_PLAN_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${READING_PLAN_TABLE}(
      ${readingPlanId} ${INTEGER} PRIMARY KEY NOT NULL,
      ${readingName} ${TEXT},
      ${readingPlanAliasName} ${TEXT},
      ${readingActive} ${INTEGER} DEFAULT 0);`,
  );
};

export const reCreateReadingPlanTableAndData = async (tx, cmds) => {
  await createReadingPlanTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertReadingPlan = async (tx, readingPlan) => {
  await insertData(tx, READING_PLAN_TABLE, keys, readingPlan);
};

export const deleteAllReadingPlans = async tx => {
  await deleteData(tx, READING_PLAN_TABLE);
};

export const deleteReadingPlan = async (tx, readingPlan) => {
  await deleteData(tx, READING_PLAN_TABLE, readingPlan[readingPlanId]);
};

export const updateReadingPlan = async (tx, readingPlan) => {
  await updateData(
    tx,
    READING_PLAN_TABLE,
    readingPlan,
    readingPlanId,
  );
};

export const getReadingPlans = async tx => {
  const readingPlans = await selectData(tx, READING_PLAN_TABLE, keys);
  return isNotEmpty(readingPlans) ? readingPlans : [];
};

export const getReadingPlan = async (tx, dataId) => {
  const readingPlans = await selectData(
    tx,
    READING_PLAN_TABLE,
    keys,
    readingPlanId,
    dataId,
    [], 
    null,
    'LIMIT 1'
  );
  return isNotEmpty(readingPlans) ? readingPlans[0] : null;
};

export const checkReadingPlanTable = async tx => {
  return await checkTableExists(tx, READING_PLAN_TABLE);
};
