export const registerEventListeners = (interfaceExecuteCall, debounce) => {
  for (
    var e = document.querySelectorAll('.footnote'), t = 0;
    t < e.length;
    t++
  ) {
    e[t].addEventListener('click', function (e) {
      var t;
      e.currentTarget && (t = e.currentTarget.getAttribute('id')),
        (t =
          t ||
          e.target.getAttribute('id') ||
          e.target.parentNode.getAttribute('id')),
        interfaceExecuteCall('activateFootnote', t);
    });
  }

  for (
    var n = document.querySelectorAll('.front-arrow'), t = 0;
    t < n.length;
    t++
  ) {
    n[t].addEventListener('click', function () {
      interfaceExecuteCall('nextChapter');
    });
  }

  document.addEventListener(
    'selectionchange',
    debounce(function (event) {
      let selection = document.getSelection
        ? document.getSelection().toString()
        : document.selection.createRange().toString();
      interfaceExecuteCall(selection);
    }, 250),
  );
};

export const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};
