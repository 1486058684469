import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {Text, TouchableOpacity, View} from 'react-native';
import {positionInBook} from '../../constants/stringsAndFields';

const ChapterGridItem = ({chapter, onItemPress}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);

  return (
    <View style={global.gridItemWrapper}>
      <TouchableOpacity
        style={global.gridItem}
        onPress={() => onItemPress(chapter)}>
        <Text style={global.textMain}>{chapter[positionInBook]}</Text>
      </TouchableOpacity>
    </View>
  );
};
export default ChapterGridItem;
