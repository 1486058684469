import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from '../translations/en.json';
import hr from '../translations/hr.json';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {STORAGE_LANGUAGE} from '../constants/stringsAndFields';

export const DEFAULT_LANG = 'hr';
export const FALLBACK_LANG = 'en';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async cb => {
    const storedLang = await AsyncStorage.getItem(STORAGE_LANGUAGE);
    if (storedLang) {
      cb(storedLang);
    } else {
      cb(DEFAULT_LANG);
    }
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          ...en,
        },
      },
      hr: {
        translation: {
          ...hr,
        },
      },
    },
    fallbackLng: FALLBACK_LANG,
    compatibilityJSON: 'v3',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
