import React from 'react';
import {FlatList} from 'react-native';
import {id} from '../../constants/stringsAndFields';
import VerseGridItem from './VerseGridItem';

const VersesGridList = ({verses, onItemPress}) => {
  const renderItem = ({item}) => (
    <VerseGridItem verse={item} onItemPress={onItemPress} />
  );

  return (
    <FlatList
      data={verses}
      renderItem={renderItem}
      keyExtractor={item => item[id]}
      numColumns={7}
      contentContainerStyle={{padding: 15}}
    />
  );
};

export default VersesGridList;
