import {useContext, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {UIScreenWrapper} from '../components/ui-components/UIScreenWrapper';
import {UIScreenContainer} from '../components/ui-components/UIScreenContainer';
import {
  ALL_TESTAMENT_CODE,
  NEW_TESTAMENT_CODE,
  OLD_TESTAMENT_CODE,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {debounceFunction} from '../utils/promise';
import {useTheme} from '../theme/ThemeContext';
import globalStyle from '../theme/globalStyle';
import UITextInput from '../components/ui-components/UITextInput';
import UIButton from '../components/ui-components/UIButton';
import SearchVersesList from '../components/search/SearchVersesList';

const SearchScreen = ({navigation}) => {
  const {getVersesByQueryAndTestamentFromBible} = useContext(DataContext);
  const [verses, setVerses] = useState([]);
  const [testament, setTestament] = useState(ALL_TESTAMENT_CODE);
  const [searchText, setSearchText] = useState('');

  const [buttonTypes, setButtonTypes] = useState({
    allTestament: 'primary',
    oldTestament: 'clear',
    newTestament: 'clear',
  });

  const {theme} = useTheme();
  const global = globalStyle(theme);

  useEffect(() => {
    if (searchText) {
      onSearch(searchText).catch(console.error);
    }
  }, [testament]);

  const onSearch = async text => {
    const delay = !text ? 100 : 500;
    const delayedQuery = debounceFunction(() => {
      getVersesByQueryAndTestamentFromBible(text, testament).then(res => {
        setVerses(res);
      });
    }, delay);
    delayedQuery && delayedQuery();
    setSearchText(text);
  };

  const onVerseClick = async item => {
    navigation.push('Tabs', {
      screen: 'Biblija',
      params: {
        verse: item,
      },
    });
  };

  return (
    <UIScreenWrapper>
      <UIScreenContainer>
        <UITextInput
          onChangeText={onSearch}
          containerStyle={{marginBottom: 15}}
        />
        <View style={[global.flexCenter, {marginBottom: 15}]}>
          <UIButton
            onPress={() => {
              setTestament(ALL_TESTAMENT_CODE);
              setButtonTypes({
                ...buttonTypes,
                allTestament: 'primary',
                oldTestament: 'clear',
                newTestament: 'clear',
              });
            }}
            title="Cijela Biblija"
            type={buttonTypes.allTestament}
            style={styles.btn}
            titleStyle={{fontSize: 12}}
          />
          <UIButton
            onPress={() => {
              setTestament(OLD_TESTAMENT_CODE);
              setButtonTypes({
                ...buttonTypes,
                allTestament: 'clear',
                oldTestament: 'primary',
                newTestament: 'clear',
              });
            }}
            title="Stari zavjet"
            type={buttonTypes.oldTestament}
            style={[styles.btn, {borderLeftWidth: 0, borderRightWidth: 0}]}
            titleStyle={{fontSize: 12}}
          />
          <UIButton
            onPress={() => {
              setTestament(NEW_TESTAMENT_CODE);
              setButtonTypes({
                ...buttonTypes,
                allTestament: 'clear',
                oldTestament: 'clear',
                newTestament: 'primary',
              });
            }}
            title="Novi zavjet"
            type={buttonTypes.newTestament}
            style={styles.btn}
            titleStyle={{fontSize: 12}}
          />
        </View>
        <SearchVersesList verses={verses} onVerseClick={onVerseClick} searchQuery={searchText} />
      </UIScreenContainer>
    </UIScreenWrapper>
  );
};

const styles = StyleSheet.create({
  btn: {
    flex: 1,
    borderRadius: 0,
    paddingHorizontal: 0,
  },
});

export default SearchScreen;
