import React from 'react';
import {FlatList} from 'react-native';
import FavoriteQuoteItem from './FavoriteQuoteItem';
import UIListEmpty from '../ui-components/UIListEmpty';
import {useTheme} from '../../theme/ThemeContext';

const FavoriteQuoteList = ({quotes, onDeleteQuote}) => {
  const {theme} = useTheme();

  const render = ({item}) => (
    <FavoriteQuoteItem quoteItem={item} onDeleteQuote={onDeleteQuote} />
  );

  const listEmpty = () => (
    <UIListEmpty icon="heart" text="Nema omiljenih citata" />
  );

  return (
    <FlatList
      data={quotes}
      renderItem={render}
      keyExtractor={item => item.id.toString()}
      ListEmptyComponent={listEmpty}
      contentContainerStyle={{paddingBottom: theme.navHeight}}
    />
  );
};

export default FavoriteQuoteList;
