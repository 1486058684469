import {
  INTEGER,
  TEXT,
  additionalBook,
  audioDownloaded,
  book_title,
  book_title_idx,
  chapter,
  chapterId,
  content,
  isIntroBook,
  positionInBook,
  title,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrays';
import {BOOK_TABLE} from './book';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectComparedDataBy,
  selectData,
  selectDataMultiWhere,
  updateData,
} from './common';
import {runSqlCmd} from './sqlOperations';

export const CHAPTER_TABLE = 'Chapter';
const keys = [
  chapterId,
  book_title,
  content,
  isIntroBook,
  positionInBook,
  additionalBook,
  audioDownloaded
];

// TODO: add index which is used for query
export const createChapterTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${CHAPTER_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${CHAPTER_TABLE}(
      ${chapterId} ${TEXT} PRIMARY KEY NOT NULL,
      ${book_title} ${TEXT},
      ${content} ${TEXT},
      ${isIntroBook} ${INTEGER} DEFAULT 0,
      ${positionInBook} ${INTEGER},
      ${additionalBook} ${INTEGER} DEFAULT 0,
      ${audioDownloaded} ${INTEGER} DEFAULT 0,
      FOREIGN KEY(${book_title}) REFERENCES ${BOOK_TABLE}(${title}) ON UPDATE NO ACTION ON DELETE NO ACTION);`,
  );
  await runSqlCmd(
    tx,
    `CREATE INDEX ${book_title_idx} ON ${CHAPTER_TABLE} (${book_title});`,
  );
};

export const reCreateChapterTableAndData = async (tx, cmds) => {
  await createChapterTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertChapter = async (tx, chapter) => {
  await insertData(tx, CHAPTER_TABLE, keys, chapter);
};

export const deleteAllChapters = async tx => {
  await deleteData(tx, CHAPTER_TABLE);
};

export const deleteChapter = async (tx, chapter) => {
  await deleteData(tx, CHAPTER_TABLE, chapter[chapterId]);
};

export const updateChapter = async (tx, chapter) => {
  await updateData(tx, CHAPTER_TABLE, chapter, chapterId);
};

export const getChapters = async tx => {
  const chapters = await selectData(tx, CHAPTER_TABLE, keys);
  return isNotEmpty(chapters) ? chapters : [];
};

export const getNextChapter = async (tx, dataId, isBibleChapter) => {
  const chapters = await selectComparedDataBy(
    tx,
    CHAPTER_TABLE,
    keys,
    chapterId,
    dataId,
    isBibleChapter ? additionalBook : null,
    isBibleChapter ? isIntroBook : null,
    [`CAST(${chapterId} AS INTEGER)`, 'ASC'],
    null,
    '>',
    'LIMIT 1',
  );
  return isNotEmpty(chapters) ? chapters[0] : null;
};

export const getPreviousChapter = async (tx, dataId, isBibleChapter) => {
  const chapters = await selectComparedDataBy(
    tx,
    CHAPTER_TABLE,
    keys,
    chapterId,
    dataId,
    isBibleChapter ? additionalBook : null,
    isBibleChapter ? isIntroBook : null,
    [`CAST(${chapterId} AS INTEGER)`, 'DESC'],
    null,
    '<',
    'LIMIT 1',
  );
  return isNotEmpty(chapters) ? chapters[0] : null;
};

export const getChapter = async (tx, dataId) => {
  const chapters = await selectData(
    tx,
    CHAPTER_TABLE,
    keys,
    chapterId,
    dataId,
    [],
    null,
    'LIMIT 1',
  );
  return isNotEmpty(chapters) ? chapters[0] : null;
};

export const checkChapterTable = async tx => {
  return await checkTableExists(tx, CHAPTER_TABLE);
};

export const getChapterByVerse = async (tx, verse) => {
  const chapterValue = await selectDataMultiWhere(
    tx,
    CHAPTER_TABLE,
    keys,
    [book_title, positionInBook],
    [verse[book_title], verse[chapter]],
    [],
    false,
  );
  return isNotEmpty(chapterValue) ? chapterValue[0] : null;
};

export const getChaptersByBookTitle = async (tx, book) => {
  const chapters = await selectData(
    tx,
    CHAPTER_TABLE,
    keys,
    book_title,
    book[title],
  );
  return isNotEmpty(chapters) ? chapters : [];
};

export const getChapterByBookTitleAndChapter = async (
  tx,
  bookTitle,
  chapterNumber,
) => {
  const chapterValue = await selectDataMultiWhere(
    tx,
    CHAPTER_TABLE,
    keys,
    [book_title, positionInBook],
    [bookTitle, chapterNumber],
    [],
    false,
  );
  return isNotEmpty(chapterValue) ? chapterValue[0] : null;
};

export const getParentBookFromDB = async (tx, bookTitleVal) => {
  const chapterValue = await selectData(
    tx,
    BOOK_TABLE,
    keys,
    title,
    bookTitleVal,
  );
  return isNotEmpty(chapterValue) ? chapterValue[0] : null;
};
