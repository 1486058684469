import {
  INTEGER,
  TEXT,
  content,
  id,
  keyword,
  ref,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrays';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  updateData,
} from './common';
import {runSqlCmd} from './sqlOperations';

export const FOOTNOTE_TABLE = 'Footnote';
const keys = [id, keyword, ref, content];

// TODO: add index which is used for query
export const createFootnoteTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${FOOTNOTE_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${FOOTNOTE_TABLE}(
      ${id} ${TEXT} PRIMARY KEY NOT NULL,
      ${keyword} ${TEXT},
      ${ref} ${INTEGER},
      ${content} ${TEXT});`,
  );
};

export const reCreateFootnoteTableAndData = async (tx, cmds) => {
  await createFootnoteTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertFootnote = async (tx, book) => {
  await insertData(tx, FOOTNOTE_TABLE, keys, book);
};

export const deleteAllFootnotes = async tx => {
  await deleteData(tx, FOOTNOTE_TABLE);
};

export const deleteFootnote = async (tx, footnote) => {
  await deleteData(tx, FOOTNOTE_TABLE, footnote[id]);
};

export const updateFootnote = async (tx, footnote) => {
  await updateData(tx, FOOTNOTE_TABLE, footnote, id);
};

export const getFootnotes = async tx => {
  const footnotes = await selectData(tx, FOOTNOTE_TABLE, keys);
  return isNotEmpty(footnotes) ? footnotes : [];
};

export const getFootnote = async (tx, dataId) => {
  const footnotes = await selectData(
    tx,
    FOOTNOTE_TABLE,
    keys,
    id,
    dataId,
    [],
    null,
    'LIMIT 1',
  );
  return isNotEmpty(footnotes) ? footnotes[0] : null;
};

export const checkFootnoteTable = async tx => {
  return await checkTableExists(tx, FOOTNOTE_TABLE);
};
