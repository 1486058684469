import {
  INTEGER,
  TEXT,
  chapterId,
  chapter_chapterId,
  id,
  label,
  position,
  quote,
  timestamp,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrays';
import {CHAPTER_TABLE} from './chapter';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  updateData,
} from './common';
import {runSqlCmd, exportDatabase} from './sqlOperations';

export const FAVORITE_QUOTE_TABLE = 'FavoriteQuote';
const keys = [id, chapter_chapterId, quote, label, position, timestamp];

// TODO: add index which is used for query
export const createFavoriteQuoteTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${FAVORITE_QUOTE_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${FAVORITE_QUOTE_TABLE}(
      ${id} ${TEXT} PRIMARY KEY NOT NULL,
      ${chapter_chapterId} ${TEXT},
      ${quote} ${TEXT},
      ${label} ${TEXT},
      ${position} ${INTEGER},
      ${timestamp} ${INTEGER},
      FOREIGN KEY(${chapter_chapterId}) REFERENCES ${CHAPTER_TABLE}(${chapterId}) ON UPDATE NO ACTION ON DELETE NO ACTION);`,
  );
};

export const reCreateFavoriteQuoteTableAndData = async (tx, cmds) => {
  await createFavoriteQuoteTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertFavoriteQuote = async (tx, favoriteQuote) => {
  await insertData(tx, FAVORITE_QUOTE_TABLE, keys, favoriteQuote);
  await exportDatabase(tx);
};

export const deleteAllFavoriteQuotes = async tx => {
  await deleteData(tx, FAVORITE_QUOTE_TABLE);
  await exportDatabase(tx);
};

export const deleteFavoriteQuote = async (tx, favoriteQuote) => {
  await deleteData(tx, FAVORITE_QUOTE_TABLE, favoriteQuote[id]);
  await exportDatabase(tx);
};

export const updateFavoriteQuote = async (tx, favoriteQuote) => {
  await updateData(tx, FAVORITE_QUOTE_TABLE, favoriteQuote, id);
  await exportDatabase(tx);
};

export const getFavoriteQuotes = async tx => {
  const favoriteQuotes = await selectData(tx, FAVORITE_QUOTE_TABLE, keys);
  return isNotEmpty(favoriteQuotes) ? favoriteQuotes : [];
};

export const getFavoriteQuote = async (tx, dataId) => {
  const favoriteQuotes = await selectData(
    tx,
    FAVORITE_QUOTE_TABLE,
    keys,
    id,
    dataId,
    [],
    false,
    'LIMIT 1',
  );
  return isNotEmpty(favoriteQuotes) ? favoriteQuotes[0] : null;
};

export const checkFavoriteQuoteTable = async tx => {
  return await checkTableExists(tx, FAVORITE_QUOTE_TABLE);
};
