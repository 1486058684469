import {View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

export const UIScreenWrapper = ({children}) => {
  const {theme} = useTheme();

  return (
    <View
      style={{
        flexGrow: 1,
        backgroundColor: theme.colors.background,
      }}>
      {children}
    </View>
  );
};
