import React from 'react';
import {Text, TouchableOpacity} from 'react-native';
import Icon from '../ui-components/Icon';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {
  readingContent,
  readingCompleted,
} from '../../constants/stringsAndFields';

const ReadingPlanContentItem = ({readingContentItem, onItemPress}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);

  const isPressDisabled = readingContentItem[readingContent].includes('nadoknada');

  return (
    <TouchableOpacity
      style={[
        global.listItem,
        {justifyContent: 'flex-start', alignItems: 'center'},
      ]}
      onPress={() => onItemPress(readingContentItem)} disabled={isPressDisabled}>
      {readingContentItem[readingCompleted] == true ? (
        <Icon name="check-circle" color={theme.colors.primary} size={18} />
      ) : (
        <Icon name="circle" color={theme.colors.icon} size={18} />
      )}
      <Text style={[global.textBold, {marginLeft: 10}]}>
        {readingContentItem[readingContent]}
      </Text>
    </TouchableOpacity>
  );
};

export default ReadingPlanContentItem;
