import {BottomTabBar} from '@react-navigation/bottom-tabs';
import React, {useContext, useEffect, useState} from 'react';
import {Animated} from 'react-native';
import AudioPlayer from '../../components/audio-player/AudioPlayer';
import DataContext from '../../context/dataContext';
import {IS_MOBILE} from '../../utils/platform';

const CustomBottomTabBar = props => {
  const {hideTabBar} = useContext(DataContext);
  const [animation] = useState(new Animated.Value(0));

  const translateY = animation.interpolate({
    inputRange: [0, 54],
    outputRange: [54, 0],
  });

  const startAnimation = () => {
    Animated.timing(animation, {
      toValue: hideTabBar ? 0 : 54,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    startAnimation();
  }, [hideTabBar]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        transform: [{translateY}],
      }}>
      {IS_MOBILE && <AudioPlayer />}
      <BottomTabBar {...props} style={{position: 'relative'}} />
    </Animated.View>
  );
};

export default CustomBottomTabBar;
