import {StyleSheet, Text, View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

export const UINavHeader = ({title, style, headerLeft, headerRight}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  return (
    <View style={[styles.container, style]}>
      {headerLeft && <View style={styles.headerLeft}>{headerLeft}</View>}
      <Text style={styles.title} numberOfLines={1}>
        {title}
      </Text>
      {headerRight && <View style={styles.headerRight}>{headerRight}</View>}
    </View>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.colors.navBackground,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 100,
      height: theme.navHeight,
      shadowColor: theme.colors.shadow,
      shadowOpacity: 0.4,
      shadowOffset: {width: 0, height: 1},
      shadowRadius: 10,
      elevation: 5,
    },

    title: {
      fontFamily: theme.fontFamily,
      color: theme.colors.text,
      fontSize: 18,
      flex: 1,
      textAlign: 'center',
    },

    headerLeft: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
    },

    headerRight: {
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      justifyContent: 'center',
    },
  });
};

export default UINavHeader;
