import {IS_WEB} from '../../utils/platform';
import {UIScreenScrollWrapper} from './UIScreenScrollWrapper';
import {UIScreenWrapper} from './UIScreenWrapper';

export const UIScreenReaderWrapper = ({children, scrollCallback}) => {
  if (IS_WEB) {
    return (
      <UIScreenScrollWrapper scrollCallback={scrollCallback}>
        {children}
      </UIScreenScrollWrapper>
    );
  }
  return <UIScreenWrapper>{children}</UIScreenWrapper>;
};

export default UIScreenReaderWrapper;
