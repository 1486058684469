import {StyleSheet, View} from 'react-native';
import UIModal from '../ui-components/UIModal';
import UIButton from '../ui-components/UIButton';

const ActionPickerModal = ({
  modalVisible,
  setModalVisible,
  setSelectedText,
  selectedText,
  handleSelectionEvent,
}) => {
  const closeModal = () => {
    setModalVisible(!modalVisible);
    setSelectedText('');
  };
  return (
    <UIModal
      visible={modalVisible}
      onClose={() => setModalVisible(!modalVisible)}>
      <View style={styles.btnWrapper}>
        <UIButton
          title="Kopiraj"
          type="outline"
          style={{marginTop: 20}}
          onPress={_ => {
            const text = selectedText;
            closeModal();
            handleSelectionEvent({key: 'copy', selectedText: text});
          }}></UIButton>
        <UIButton
          title="Dodaj u omiljene"
          type="outline"
          style={{marginTop: 20}}
          onPress={_ => {
            const text = selectedText;
            closeModal();
            handleSelectionEvent({
              key: 'favorite',
              selectedText: text,
            });
          }}></UIButton>
        <UIButton
          title="Dodaj bilješku"
          type="outline"
          style={{marginTop: 20}}
          onPress={_ => {
            closeModal();
            handleSelectionEvent({key: 'note', selectedText: null});
          }}></UIButton>
        <UIButton
          title="Odustani"
          style={{marginVertical: 20}}
          onPress={closeModal}></UIButton>
      </View>
    </UIModal>
  );
};

const styles = StyleSheet.create({
  btnWrapper: {
    width: '80%',
    marginHorizontal: 'auto',
  },
});

export default ActionPickerModal;
