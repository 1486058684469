import {Text} from 'react-native';
import UIModal from '../ui-components/UIModal';
import {content, keyword, ref} from '../../constants/stringsAndFields';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';

const FootnoteModal = ({footnoteContent, modalVisible, setModalVisible}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);

  return (
    <UIModal
      visible={modalVisible}
      onClose={() => setModalVisible(!modalVisible)}>
      <Text style={global.textLight}>
        {footnoteContent?.[keyword]} {footnoteContent?.[ref]}
      </Text>
      <Text style={[global.textLarger, {marginTop: 10}]}>
        {footnoteContent?.[content]}
      </Text>
    </UIModal>
  );
};

export default FootnoteModal;
