import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import UIBottomSheet from '../ui-components/UIBottomSheet';
import {StyleSheet, Text, View} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {getLastReading} from '../../utils/storage';
import {useContext, useEffect, useState} from 'react';
import {
  AUDIO_PARENT_LINK,
  audioBookName,
  book_title,
  positionInBook,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';

export const AudioBottomSheet = ({modalVisible, setModalVisible}) => {
  const {getChapter, getBookByName} = useContext(DataContext);
  const {theme} = useTheme();
  const global = globalStyle(theme);
  const styles = getStyles(theme);
  const [chapterAudioLink, setChapterAudioLink] = useState(null);
  const [listeningChapter, setListeningChapter] = useState(null);

  useEffect(() => {
    if (modalVisible) {
      const initLink = async () => {
        try {
          const lastReadingChapterId = await getLastReading();
          const chapter = await getChapter(lastReadingChapterId);
          const book = await getBookByName(chapter[book_title]);
          if (chapter && book && chapter[positionInBook] >= 0) {
            const chapterLink = `${AUDIO_PARENT_LINK}/${book[audioBookName]}/${chapter[positionInBook]}.m4a`;
            setChapterAudioLink(chapterLink);
            setListeningChapter(chapter);
          }
        } catch (e) {
          console.log(e);
        }
      };
      initLink().catch(console.error);
    }
  }, [modalVisible]);

  return (
    <UIBottomSheet
      visible={modalVisible}
      onClose={() => setModalVisible(!modalVisible)}>
      <Text style={global.textMain}>
        {listeningChapter?.[book_title]} {listeningChapter?.[positionInBook]}
      </Text>
      <View style={styles.controlsWrapper}>
        <AudioPlayer src={chapterAudioLink} />
      </View>
    </UIBottomSheet>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    controlsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 10,
    },

    controlbBtn: {
      padding: 20,
    },
  });
};
