import React, {createContext, useContext, useEffect, useState} from 'react';
import {StatusBar} from 'react-native';
import {
  readingFontStyles,
  readingFontSizes,
  themes,
  getThemeByValue,
} from './themes';
import {IS_ANDROID} from '../utils/platform';
import {
  THEME_FONTS,
  THEME_MODE,
  THEME_SIZE,
} from '../constants/stringsAndFields';
import {saveThemeSettings} from '../utils/storage';

// Create a ThemeContext
const ThemeContext = createContext();

// Create a custom hook for consuming the ThemeContext
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({themeSettings, children}) => {
  const [theme, setTheme] = useState(
    getThemeByValue(themeSettings[THEME_MODE]) ?? themes.light,
  );
  const [fontStyle, setFontStyle] = useState(
    themeSettings[THEME_FONTS] ?? readingFontStyles.serif,
  );
  const [fontSize, setFontSize] = useState(
    themeSettings[THEME_SIZE] ?? readingFontSizes.normal,
  );

  useEffect(() => {
    if (IS_ANDROID) {
      StatusBar.setBackgroundColor(theme.statusBar.backgroundColor);
    }
    StatusBar.setBarStyle(
      theme.statusBar.textColor === '#ffffff'
        ? 'light-content'
        : 'dark-content',
    );
  }, [theme]);

  const updateTheme = async selectedTheme => {
    let newTheme;

    switch (selectedTheme) {
      case themes.dark.name:
        newTheme = themes.dark;
        break;
      case themes.sepia.name:
        newTheme = themes.sepia;
        break;
      default:
        newTheme = themes.light;
    }
    await saveThemeSettings(THEME_MODE, newTheme.name);
    setTheme(newTheme);
  };

  const updateFontStyle = async selectedFont => {
    let newFont;
    switch (selectedFont) {
      case readingFontStyles.sans:
        newFont = readingFontStyles.sans;
        break;
      case readingFontStyles.serif:
        newFont = readingFontStyles.serif;
        break;
      default:
        newFont = readingFontStyles.serif;
    }
    await saveThemeSettings(THEME_FONTS, newFont);
    setFontStyle(newFont);
  };

  const updateFontSize = async selectedSize => {
    let newSize;
    switch (selectedSize) {
      case readingFontSizes.small:
        newSize = readingFontSizes.small;
        break;
      case readingFontSizes.large:
        newSize = readingFontSizes.large;
        break;
      default:
        newSize = readingFontSizes.normal;
    }
    await saveThemeSettings(THEME_SIZE, newSize);
    setFontSize(newSize);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
        updateTheme,
        updateFontStyle,
        updateFontSize,
        fontStyle,
        fontSize,
      }}>
      {children}
    </ThemeContext.Provider>
  );
};
