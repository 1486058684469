import React from 'react';
import {Pressable} from 'react-native';

export const UIPressable = ({children, onPress, disabled, style}) => {
  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      style={({pressed}) => [
        {
          opacity: pressed ? 0.5 : 1,
        },
        style,
      ]}>
      {children}
    </Pressable>
  );
};
