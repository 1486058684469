import {useNavigation} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {
  chapter_chapterId,
  label,
  positionInBook,
  quote,
  timestamp,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {convertTimeStampToString} from '../../utils/dateTime';
import Icon from '../ui-components/Icon';
import {UIPressable} from '../ui-components/UIPressable';

const FavoriteQuoteItem = ({quoteItem, onDeleteQuote}) => {
  const {getChapter} = useContext(DataContext);
  const navigation = useNavigation();
  const [chapter, setChapter] = useState(null);
  const {theme} = useTheme();
  const global = globalStyle(theme);

  const handleDeleteClick = async e => {
    e.stopPropagation();
    await onDeleteQuote(quoteItem);
  };

  const openReadingScreen = () => {
    if (chapter) {
      navigation.navigate('Tabs', {
        screen: 'Biblija',
        params: {
          chapter: chapter,
        },
      });
    }
  };

  useEffect(() => {
    getChapter(quoteItem[chapter_chapterId]).then(chapter =>
      setChapter(chapter),
    );
  }, []);

  return (
    <TouchableOpacity style={global.listItem} onPress={openReadingScreen}>
      <View style={global.listItemTextContainer}>
        <Text style={global.listItemTitle}>
          {quoteItem[label]} {chapter?.[positionInBook]}
        </Text>
        <Text style={global.listItemContent}>{quoteItem[quote]}</Text>
        <Text style={global.listItemDate}>
          {convertTimeStampToString(quoteItem[timestamp])}
        </Text>
      </View>
      <UIPressable onPress={handleDeleteClick} style={{padding: 5}}>
        <Icon name="trash" style={global.listItemIcon} />
      </UIPressable>
    </TouchableOpacity>
  );
};

export default FavoriteQuoteItem;
