import * as React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import BooksScreen from '../screens/BooksScreen';
import ChaptersScreen from '../screens/ChaptersScreen';
import FavoritesScreen from '../screens/FavoritesScreen';
import NotesScreen from '../screens/NotesScreen';
import ReadingPlanScreen from '../screens/ReadingPlanScreen';
import ReadingPlanDetailsScreen from '../screens/ReadingPlanDetailsScreen';
import ReadingScreen from '../screens/ReadingScreen';
import SettingsScreen from '../screens/SettingsScreen';
import {useTheme} from '../theme/ThemeContext';
import SearchScreen from '../screens/SearchScreen';
import VersesScreen from '../screens/VersesScreen';
import {UIPressable} from '../components/ui-components/UIPressable';
import Icon from '../components/ui-components/Icon';
import NotesDetailScreen from '../screens/NotesDetailScreen';
import {getHeaderTitle} from '@react-navigation/elements';
import UINavHeader from '../components/ui-components/UINavHeader';

import CustomBottomTabBar from '../components/reading/CustomBottomTabBar';
import DataContext from '../context/dataContext';

/*
    // Main Screen
    // Books Screen
    // Chapters Scree
    // Favorites Screen
    // Home Screen ?
    // Notes Screen
    // Reading Chapter Screen
    // Reading Plan Chapter Screen
    // Settings Screen
    // Terms Screen
    // Verses Screen
    // Search Screen
*/

const Stack = createNativeStackNavigator();
const ReadingNavigator = () => {
  const {theme} = useTheme();
  return (
    <Stack.Navigator
      screenOptions={({navigation}) => ({
        header: ({navigation, route, options}) => {
          const title = getHeaderTitle(options, route.name);

          const headerLeft = (
            <UIPressable onPress={() => navigation.goBack()}>
              <Icon
                name="arrow-left"
                color={theme.colors.text}
                size={18}
                style={{padding: theme.hMargin}}
              />
            </UIPressable>
          );

          return (
            <UINavHeader title={title} headerLeft={headerLeft}></UINavHeader>
          );
        },
      })}>
      <Stack.Screen
        name="Books"
        component={BooksScreen}
        options={{
          headerShown: true,
          title: 'Knjige',
        }}
      />
      <Stack.Screen
        name="Chapters"
        component={ChaptersScreen}
        options={{
          headerShown: true,
          title: 'Poglavlje',
        }}
      />
      <Stack.Screen
        name="Verses"
        component={VersesScreen}
        options={{
          headerShown: true,
          title: 'Stih',
        }}
      />
      <Stack.Screen
        name="Search"
        component={SearchScreen}
        options={{
          headerShown: true,
          title: 'Pretraga',
        }}
      />
      <Stack.Screen
        name="PlanDetails"
        component={ReadingPlanDetailsScreen}
        options={{
          headerShown: true,
          title: 'Plan čitanja',

          header: ({navigation, route, options}) => {
            const title = getHeaderTitle(options, route.name);

            const headerLeft = (
              <UIPressable onPress={() => navigation.goBack()}>
                <Icon
                  name="arrow-left"
                  color={theme.colors.text}
                  size={18}
                  style={{padding: theme.hMargin}}
                />
              </UIPressable>
            );

            return (
              <UINavHeader
                title={title}
                style={{paddingHorizontal: 50}}
                headerLeft={headerLeft}></UINavHeader>
            );
          },
        }}
      />
      <Stack.Screen
        name="NotesDetail"
        component={NotesDetailScreen}
        options={{
          headerShown: true,
          title: 'Bilješka',
        }}
      />
    </Stack.Navigator>
  );
};

const Tab = createBottomTabNavigator();
const MyTabs = ({navigation}) => {
  const {theme} = useTheme();
  const {hideTabBar} = React.useContext(DataContext);

  return (
    <Tab.Navigator
      sceneContainerStyle={{backgroundColor: theme.colors.background}}
      tabBar={props => <CustomBottomTabBar {...props} />}
      screenOptions={{
        tabBarStyle: {
          backgroundColor: theme.colors.navBackground,
          borderTopWidth: 0,
          height: theme.navHeight,
          paddingBottom: 5,
          paddingTop: 3,
          shadowColor: theme.colors.shadow,
          shadowOpacity: 0.4,
          shadowOffset: {width: 0, height: -1},
          shadowRadius: 10,
          elevation: 5,
        },
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: theme.colors.tabIcon,
        tabBarLabelStyle: {
          color: theme.colors.text,
          fontFamily: theme.fontFamily,
        },

        header: ({route, options}) => {
          const title = getHeaderTitle(options, route.name);
          return <UINavHeader title={title} />;
        },
      }}>
      <Tab.Screen
        name="Biblija"
        component={ReadingScreen}
        options={{
          tabBarLabel: 'Biblija',
          tabBarIcon: ({color}) => <Icon name="home" color={color} size={18} />,
        }}
        listeners={{
          tabPress: e => {
            if (hideTabBar) {
              e.preventDefault();
            }
          },
        }}
      />
      <Tab.Screen
        name="Plan čitanja"
        component={ReadingPlanScreen}
        options={{
          tabBarLabel: 'Plan',
          tabBarIcon: ({color}) => (
            <Icon name="calendar" color={color} size={18} />
          ),
        }}
        listeners={{
          tabPress: e => {
            if (hideTabBar) {
              e.preventDefault();
            }
          },
        }}
      />
      <Tab.Screen
        name="Omiljeni citati"
        component={FavoritesScreen}
        options={{
          tabBarLabel: 'Omiljeni',
          tabBarIcon: ({color}) => (
            <Icon name="heart" color={color} size={18} />
          ),
        }}
        listeners={{
          tabPress: e => {
            if (hideTabBar) {
              e.preventDefault();
            }
          },
        }}
      />
      <Tab.Screen
        name="Bilješke"
        component={NotesScreen}
        options={{
          tabBarLabel: 'Bilješke',
          tabBarIcon: ({color}) => <Icon name="note" color={color} size={18} />,
        }}
        listeners={{
          tabPress: e => {
            if (hideTabBar) {
              e.preventDefault();
            }
          },
        }}
      />
      <Tab.Screen
        name="Postavke"
        component={SettingsScreen}
        options={{
          tabBarLabel: 'Postavke',
          tabBarIcon: ({color}) => (
            <Icon name="settings" color={color} size={18} />
          ),
        }}
        listeners={{
          tabPress: e => {
            if (hideTabBar) {
              e.preventDefault();
            }
          },
        }}
      />
    </Tab.Navigator>
  );
};

export default function Navigation() {
  const {theme} = useTheme();

  return (
    <NavigationContainer theme={theme}>
      <Stack.Navigator>
        <Stack.Screen
          name="Tabs"
          component={MyTabs}
          options={{headerShown: false}}
        />
        <Stack.Screen
          name="Reading"
          component={ReadingNavigator}
          options={{headerShown: false}}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
