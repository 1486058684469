const baseTheme = {
  dark: false,

  /* Font: */
  fontFamily: 'OpenSans-Regular', // 400
  fontFamilyItalic: 'OpenSans-Italic', // 400 Italic
  fontFamilyBold: 'OpenSans-Bold', // 700

  fontFamilySerif: 'NotoSerif-Regular', // 400
  fontFamilySerifItalic: 'NotoSerif-Italic', // 400 Italic
  fontFamilySerifBold: 'NotoSerif-Bold', // 700

  /* Colors: */
  colors: {},

  /* Navigation */
  navHeight: 54,

  /* Margins */
  hMargin: 15,
  vMargin: 15,
};

export default baseTheme;

export const lightTheme = {
  ...baseTheme,
  name: 'lightTheme',
  colors: {
    ...baseTheme.colors,
    primary: '#c12034',
    background: '#fcfcfc',
    navBackground: '#fff',
    transparentBackground: 'rgba(250, 250, 250, 0.98)',
    text: '#222',
    textInverted: '#fff',
    textLight: '#9a9a9a',
    border: '#eaeaea',
    icon: '#a6a6a6',
    tabIcon: '#999',
    shadow: '#c8c8c8',
  },
  statusBar: {
    backgroundColor: '#fff',
    textColor: '#222',
  },
  images: {
    cfcImageSource: require('../../assets/images/cfc_logo.png'),
    bibleImageSource: require('../../assets/images/biblijska_liga_logo.png'),
    echeckinImageSource: require('../../assets/images/echeckin_logo.png'),
  },
};

const darkTheme = {
  ...baseTheme,
  name: 'darkTheme',
  colors: {
    ...baseTheme.colors,
    primary: '#fff',
    //primary: '#ec9aa4',
    background: '#343434',
    navBackground: '#292929',
    transparentBackground: 'rgba(41, 41, 41, 0.98)',
    text: '#fff',
    textInverted: '#292929',
    textLight: '#9a9a9a',
    border: '#4c4c4c',
    icon: '#a6a6a6',
    tabIcon: '#a6a6a6',
    shadow: '#000',
  },
  statusBar: {
    backgroundColor: '#292929',
    textColor: '#ffffff',
  },
  images: {
    cfcImageSource: require('../../assets/images/cfc_logo_light.png'),
    bibleImageSource: require('../../assets/images/biblijska_liga_logo_light.png'),
    echeckinImageSource: require('../../assets/images/echeckin_logo_light.png'),
  },
};

const sepiaTheme = {
  ...baseTheme,
  name: 'sepiaTheme',
  colors: {
    ...baseTheme.colors,
    primary: '#353535',
    background: '#f3e6d8',
    navBackground: '#eadbcb',
    transparentBackground: 'rgba(234, 219, 203, 0.98)',
    text: '#403126',
    textInverted: '#eadbcb',
    textLight: '#9f8a7d',
    border: '#e2cfba',
    icon: '#8c7a66',
    tabIcon: '#8d7a7a',
    shadow: '#aa9683',
  },
  statusBar: {
    backgroundColor: '#eadbcb',
    textColor: '#222',
  },
  images: {
    cfcImageSource: require('../../assets/images/cfc_logo.png'),
    bibleImageSource: require('../../assets/images/biblijska_liga_logo.png'),
    echeckinImageSource: require('../../assets/images/echeckin_logo.png'),
  },
};

export const themes = {
  light: lightTheme,
  dark: darkTheme,
  sepia: sepiaTheme,
};

export const getThemeByValue = val => {
  for (const theme of Object.keys(themes)) {
    if (themes[theme]['name'] === val) return themes[theme];
  }
  return null;
};

export const readingTheme = {
  lightTheme: 'theme-light',
  darkTheme: 'theme-dark',
  sepiaTheme: 'theme-sepia',
};

export const readingFontStyles = {
  sans: 'font-sans',
  serif: 'font-serif',
};

export const readingFontSizes = {
  small: 'font-small',
  normal: 'font-normal',
  large: 'font-large',
};

export const themeMapper = name => {
  return readingTheme[name];
};
