import React, {createContext, useContext, useState} from 'react';

const PlayerContext = createContext();

export const PlayerProvider = ({children}) => {
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const openPlayer = () => {
    setIsPlayerOpen(true);
  };

  const closePlayer = () => {
    setIsPlayerOpen(false);
  };

  return (
    <PlayerContext.Provider value={{isPlayerOpen, openPlayer, closePlayer}}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => {
  return useContext(PlayerContext);
};
