import {StyleSheet, View, Text} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import {useEffect, useState, useContext} from 'react';
import DataContext from '../../context/dataContext';

const WARNING_TIMEOUT = 4000;

export const UIConnectionStatus = ({text}) => {
  const {theme} = useTheme();
  const {setShowConnectionWarning} = useContext(DataContext);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const styles = getStyles(theme);

  useEffect(() => {
    setTimeout(() => {
      setTimeIsUp(true);
      setShowConnectionWarning(false);
    }, WARNING_TIMEOUT);
  }, []);

  if (timeIsUp) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

UIConnectionStatus.defaultProps = {
  text: 'Provjerite internet vezu ili prvo preuzmite audio knjigu!',
};

const getStyles = theme => {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.colors.primary,
      width: '100%',
      paddingHorizontal: theme.hMargin,
      paddingVertical: 2,
    },

    text: {
      color: theme.colors.textInverted,
      fontFamily: theme.fontFamily,
      fontSize: 12,
      textAlign: 'center',
    },
  });
};
