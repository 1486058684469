import React, {Suspense, useEffect, useState} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {DataProvider} from './context/dataContext';
import {initDb} from './database/sqlOperations';
import Navigation from './navigation';
import {ThemeProvider} from './theme/ThemeContext';
import UILoadingView from './components/ui-components/UILoadingView';
import './utils/i18n';
import {IS_WEB} from './utils/platform';
import {webRootCss} from './web/css';
import {PlayerProvider} from './components/audio-player/PlayerContext';
import {DEFAULT_THEME_SETTINGS} from './constants/stringsAndFields';
import {getThemeSettings} from './utils/storage';
import UIAppSafeAreaView from './components/ui-components/UIAppSafeAreaView';

const FORCE_DB_CREATE = false;

const App = () => {
  const [db, setDb] = useState();
  const [initThemeSettings, setInitThemeSettings] = useState(
    DEFAULT_THEME_SETTINGS,
  );

  useEffect(() => {
    getThemeSettings().then(themeSetting => {
      setInitThemeSettings(themeSetting);
      initDb(db, FORCE_DB_CREATE).then(initializedDb => {
        setDb(initializedDb);
      });
    });
  }, []);

  return (
    <SafeAreaProvider>
      <ThemeProvider themeSettings={initThemeSettings}>
        <UIAppSafeAreaView>
          {IS_WEB && webRootCss}
          <Suspense fallback={null}>
            {db ? (
              <DataProvider db={db}>
                <PlayerProvider>
                  <Navigation />
                </PlayerProvider>
              </DataProvider>
            ) : (
              <UILoadingView />
            )}
          </Suspense>
        </UIAppSafeAreaView>
      </ThemeProvider>
    </SafeAreaProvider>
  );
};

export default App;
