import {CommonActions} from '@react-navigation/native';
import {book_title, positionInBook} from '../constants/stringsAndFields';

export const setChapterTitle = (index, chapters, navigation) => {
  const readingChapter = chapters[index];
  if (readingChapter) {
    const position = readingChapter[positionInBook];
    const titleIndex = position === 0 ? '' : position;
    navigation.setOptions({
      title: `${readingChapter[book_title]} ${titleIndex}`,
    });
  }
};

export const resetStack = (navigation, index = -2) => {
  try {
    navigation.dispatch(state => {
      const routes = state.routes.slice(0, index);
      return CommonActions.reset({
        ...state,
        index: routes.length - 1,
        routes,
      });
    });
  } catch (e) {
    console.log(e);
  }
};
