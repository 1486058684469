import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import Icon from '../ui-components/Icon';
import {
  readingEntryId,
  readingDateLabel,
  readingEntryCompleted,
  readingCompensation,
} from '../../constants/stringsAndFields';

const ReadingPlanEntryItem = ({
  readingEntry,
  onItemPress,
  isSelected,
  isToday,
}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);

  return (
    <TouchableOpacity
      style={[
        global.listItemHorizontal,
        isSelected && global.listItemHorizontalPrimary,
        readingEntry[readingCompensation] && global.listItemHorizontalGray,
      ]}
      onPress={() => onItemPress(readingEntry)}>
      <Text style={global.listItemTitle}>{readingEntry[readingEntryId]}</Text>
      <Text
        style={[
          global.listItemDate,
          isToday ? {color: theme.colors.primary} : null,
        ]}>
        {readingEntry[readingDateLabel]}
      </Text>
      <View style={{position: 'absolute', top: 3, right: 3}}>
        {readingEntry[readingEntryCompleted] == true ? (
          <Icon name="check-circle" size={12} color={theme.colors.primary} />
        ) : (
          <Icon name="circle" size={12} color={theme.colors.icon} />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default ReadingPlanEntryItem;
