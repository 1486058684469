import {createIconSetFromIcoMoon} from 'react-native-vector-icons';
import icoMoonConfig from '../../../assets/fonts/IconFonts/selection.json';

const Icon = createIconSetFromIcoMoon(
  icoMoonConfig,
  'bibleFont',
  'bibleFont.ttf',
);

export default Icon;
