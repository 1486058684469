import {useContext, useEffect, useState} from 'react';
import {getHeaderTitle} from '@react-navigation/elements';
import ReadingView from '../components/reading/ReadingView';
import Icon from '../components/ui-components/Icon';
import UIScreenReaderWrapper from '../components/ui-components/UIScreenReadingWrapper';
import {readingCompleted, readingContent} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {useTheme} from '../theme/ThemeContext';
import {generateChapterHtml} from '../utils/html/html';
import {resolveReadingContent} from '../utils/readingPlanUtil';
import {UIPressable} from '../components/ui-components/UIPressable';
import UINavHeader from '../components/ui-components/UINavHeader';

const ReadingPlanDetailsScreen = ({navigation, route}) => {
  const {theme, fontStyle, fontSize} = useTheme();
  const {reading = null} = route.params ?? {};
  const [isCompleted, setIsCompleted] = useState(reading[readingCompleted]);
  const {
    getBookByName,
    getChapterByBookTitleAndChapter,
    setReadingCompletedAndSave,
  } = useContext(DataContext);
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    if (reading) {
      console.log('ReadingPlanDetailsScreen: ', reading);
      console.log('ReadingPlanDetailsScreen isCompleted: ', isCompleted);
      const fetchReadingData = async () => {
        const resolvedContent = await resolveReadingContent(
          reading[readingContent],
          getBookByName,
          getChapterByBookTitleAndChapter,
        );
        if (resolvedContent) {
          setHtmlContent(
            generateChapterHtml(
              resolvedContent,
              true,
              fontStyle,
              fontSize,
              theme,
            ),
          );
          navigation.setOptions({
            title: `${reading[readingContent]}`,
          });
        }
      };
      fetchReadingData().catch(console.error);
      setIsCompleted(reading[readingCompleted]);
    }
  }, [reading]);

  const onProgressClick = async _ => {
    console.log(
      'ReadingPlanDetailsScreen onProgressClick isCompleted: ',
      isCompleted,
    );
    const newCompleted = !isCompleted;
    console.log(
      'ReadingPlanDetailsScreen onProgressClick newCompleted: ',
      newCompleted,
    );
    setIsCompleted(newCompleted);
    await setReadingCompletedAndSave(reading, newCompleted);
    console.log('ReadingPlanDetailsScreen onProgressClick reading: ', reading);
    navigation.pop();
    //await resetReadingPlan('1');
  };

  useEffect(() => {
    navigation.setOptions({
      header: ({navigation, route, options}) => {
        const title = getHeaderTitle(options, route.name);

        const headerLeft = (
          <UIPressable
            onPress={() => navigation.goBack()}
            style={{padding: theme.hMargin}}>
            <Icon name="arrow-left" color={theme.colors.text} size={18} />
          </UIPressable>
        );

        const headerRight = (
          <UIPressable
            onPress={onProgressClick}
            style={{padding: theme.hMargin}}>
            <Icon
              name="check-circle"
              color={isCompleted ? theme.colors.primary : theme.colors.icon}
              size={20}
            />
          </UIPressable>
        );

        return (
          <UINavHeader
            title={title}
            headerLeft={headerLeft}
            headerRight={headerRight}></UINavHeader>
        );
      },
    });
  }, [isCompleted]);

  return (
    <UIScreenReaderWrapper>
      <ReadingView
        customHtmlContent={htmlContent}
        disableViewPager={true}
        navigation={navigation}
      />
    </UIScreenReaderWrapper>
  );
};

export default ReadingPlanDetailsScreen;
