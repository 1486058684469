import {SafeAreaView} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

export const UIAppSafeAreaView = ({children}) => {
  const {theme} = useTheme();

  return (
    <SafeAreaView
      style={{flex: 1, backgroundColor: theme.statusBar.backgroundColor}}>
      {children}
    </SafeAreaView>
  );
};

export default UIAppSafeAreaView;
