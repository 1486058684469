import React from 'react';
import BookGridItem from './BookGridItem';
import {Text, View} from 'react-native';
import {StyleSheet} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';

const BooksGridList = ({
  section,
  onBookItemPress,
  onBookSingleChapterPress,
  onSelectMode,
  selectedBooks,
  onUpdateSelectedBooks,
}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);
  const global = globalStyle(theme);

  const renderGridItem = ({item}) => (
    <BookGridItem
      book={item}
      onBookItemPress={onBookItemPress}
      onBookSingleChapterPress={onBookSingleChapterPress}
      onSelectMode={onSelectMode}
      isSelected={selectedBooks.includes(item)}
      onToggleSelect={() => onUpdateSelectedBooks(item)}
    />
  );

  return (
    <View key={section.title} style={global.gridSectionContainer}>
      {section.title && <Text style={global.gridTitle}>{section.title}</Text>}
      <View style={global.gridContainer}>
        {section.data.map(book => (
          <View
            key={book.label}
            style={[
              section.title
                ? styles.gridItemWrapper
                : styles.gridItemWrapperIntro,
            ]}>
            {renderGridItem({item: book})}
          </View>
        ))}
      </View>
    </View>
  );
};

const getStyles = theme => {
  return StyleSheet.create({
    gridItemWrapper: {
      width: '14.2%',
    },
    gridItemWrapperIntro: {
      width: '25%',
      marginTop: 15,
    },
  });
};

export default BooksGridList;
