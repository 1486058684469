import {useIsFocused} from '@react-navigation/native';
import {useContext, useEffect, useState} from 'react';
import ReadingPlanContentList from '../components/reading-plan/ReadingPlanContentList';
import ReadingPlanEntryList from '../components/reading-plan/ReadingPlanEntryList';
import UILoadingView from '../components/ui-components/UILoadingView';
import {readingEntryId, readingPlanId} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import {getIndexByReadingEntryId} from '../utils/arrays';
import {getCurrentDayOfYear} from '../utils/dateTime';

const READING_PLAN_ID = '1';

const ReadingPlanScreen = ({navigation, route}) => {
  const {getDailyReading, getDailyReadings} = useContext(DataContext);
  const [dailyReadings, setDailyReadings] = useState(null);
  const [selectedEntryItem, setSelectedEntryItem] = useState(null);
  const [readingPlanContentItems, setReadingPlanContentItems] = useState(null);
  const [initialSetupCompleted, setInitialSetupCompleted] = useState(false);
  const [scrollIndex, setScrollIndex] = useState(getCurrentDayOfYear() - 1);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused && !initialSetupCompleted) {
      initialScreenSetup().catch(console.error);
    } else if (isFocused && initialSetupCompleted) {
      refreshDailyReadings().catch(console.error);
    }
  }, [isFocused]);

  const initialScreenSetup = async () => {
    await initiallySetDailyReadings();
    setInitialSetupCompleted(true);
  };

  const refreshDailyReadings = async () => {
    const readings = await getDailyReadings(READING_PLAN_ID);
    if (readings) {
      setDailyReadings([...readings]);
    }
    const readingIndex = getIndexByReadingEntryId(
      dailyReadings,
      selectedEntryItem,
    );
    setScrollIndex(readingIndex);
    await onReadingPlanEntryItemPress(dailyReadings[readingIndex]);
  };

  const onReadingPlanEntryItemPress = async selectedItem => {
    const daily = await getDailyReading(
      selectedItem[readingPlanId],
      selectedItem[readingEntryId],
    );
    if (daily) {
      setReadingPlanContentItems(daily);
      setSelectedEntryItem(selectedItem);
    }
  };

  const onReadingPlanContentItemPress = async selectedItem => {
    navigation.navigate('Reading', {
      screen: 'PlanDetails',
      params: {reading: selectedItem},
    });
  };

  const initiallySetDailyReadings = async () => {
    const readings = await getDailyReadings(READING_PLAN_ID);
    if (readings) {
      setDailyReadings(readings);
      setSelectedEntryItem(readings[getCurrentDayOfYear() - 1]);
    }
    const daily = await getDailyReading(1, getCurrentDayOfYear());
    if (daily) {
      setReadingPlanContentItems(daily);
    }
  };

  if (!initialSetupCompleted) {
    return <UILoadingView text={''} />;
  }

  return (
    <>
      <ReadingPlanEntryList
        readingPlanEntryItems={dailyReadings}
        initialScrollIndex={scrollIndex}
        onItemPress={onReadingPlanEntryItemPress}
        selectedItem={selectedEntryItem}
      />
      <ReadingPlanContentList
        readingContentItems={readingPlanContentItems}
        onItemPress={onReadingPlanContentItemPress}
      />
    </>
  );
};

export default ReadingPlanScreen;
