import {useContext, useEffect, useRef, useState} from 'react';
import 'swiper/css';
import {
  DEFAULT_LAST_READING_CHAPTER,
  additionalBook,
  chapterId,
  isIntroBook,
  verse_number,
} from '../../constants/stringsAndFields';
import DataContext from '../../context/dataContext';
import {getIndexByChapterId, isNotNullNumber} from '../../utils/arrays';
import {copyToClipboard} from '../../utils/clipboard';
import {
  debounce,
  registerEventListeners,
} from '../../utils/html/eventListeners';
import {generateChapterHtml} from '../../utils/html/html';
import {setChapterTitle} from '../../utils/navigation';
import {generateFavoriteQuote} from '../../utils/notes';
import Icon from '../ui-components/Icon';
import {UIPressable} from '../ui-components/UIPressable';
import ActionPickerModal from './ActionPickerModal';
import EditNoteModal from './EditNoteModal';
import FootnoteModal from './FootnoteModal';
import {useTheme} from '../../theme/ThemeContext';
import {getBookmarkReading, saveLastReading} from '../../utils/storage';

const ReadingView = ({
  customHtmlContent = null,
  verse = null,
  setReadingVerse = () => {},
  chapterIndex = null,
  setShowSoundIcon,
  setBookmarkedReading,
  navigation,
}) => {
  const {
    getFootnote,
    saveFavoriteQuote,
    getBookByName,
    getNextChapter,
    getPreviousChapter,
    chapters,
  } = useContext(DataContext);
  const slideRef = useRef(null);
  const {theme, fontStyle, fontSize} = useTheme();
  const [footNoteModalVisible, setFootNoteModalVisible] = useState(false);
  const [noteEditModalVisible, setNoteEditModalVisible] = useState(false);
  const [actionPickerModalVisible, setActionPickerModalVisible] =
    useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [htmlContent, setHtmlContent] = useState('');
  const [readingChapter, setReadingChapter] = useState('');
  const [footnote, setFootnote] = useState(null);
  const isFirstChapter =
    readingChapter?.[chapterId] === DEFAULT_LAST_READING_CHAPTER;

  useEffect(() => {
    if (isNotNullNumber(chapterIndex) && chapters) {
      setReadingChapter(chapters[chapterIndex]);
    }
  }, [chapterIndex]);

  useEffect(() => {
    if (customHtmlContent) {
      setHtmlContent(customHtmlContent);
    }
  }, [customHtmlContent]);

  useEffect(() => {
    if (readingChapter && chapters) {
      setHtmlContent(
        generateChapterHtml(readingChapter, false, fontStyle, fontSize, theme),
      );
      setChapterTitle(
        getIndexByChapterId(chapters, readingChapter[chapterId]),
        chapters,
        navigation,
      );
      setShowSoundIcon(!readingChapter?.[additionalBook]);
      getBookmarkReading().then(bookmark => {
        if (bookmark && bookmark === readingChapter?.[chapterId]) {
          setBookmarkedReading(true);
        } else {
          setBookmarkedReading(false);
        }
      });
      saveLastReading(readingChapter[chapterId]).catch(console.error);
    }
  }, [readingChapter, fontStyle, fontSize, theme]);

  useEffect(() => {
    if (selectedText && !actionPickerModalVisible) {
      setActionPickerModalVisible(true);
    }
  }, [selectedText]);

  useEffect(() => {
    if (slideRef.current && htmlContent) {
      registerEventListeners(interfaceExecuteCall, debounce);
      if (verse) {
        const verseElement = slideRef.current.querySelector(
          '#V' + verse[verse_number],
        );
        verseElement && verseElement.scrollIntoView();
        setReadingVerse(null);
      } else {
        slideRef.current.scrollIntoView();
      }
    }
  }, [slideRef.current, htmlContent]);

  const openPreviousChapter = async _ => {
    if (readingChapter) {
      const c = await getPreviousChapter(readingChapter[chapterId], false);
      if (c) {
        setReadingChapter(c);
      }
    }
  };

  const openNextChapter = async _ => {
    if (readingChapter) {
      const c = await getNextChapter(readingChapter[chapterId], false);
      if (c) {
        setReadingChapter(c);
      }
    }
  };

  const interfaceExecuteCall = (e, t) => {
    if (e === 'activateFootnote') {
      getFootnote(t).then(res => {
        setFootnote(res);
        setFootNoteModalVisible(true);
      });
    } else if (e === 'nextChapter') {
      openNextChapter().catch(console.error);
    } else {
      setSelectedText(e);
    }
  };

  const handleSelectionEvent = async webViewEvent => {
    const {key, selectedText} = webViewEvent;
    if (key === 'copy') {
      copyToClipboard(selectedText);
    } else if (key === 'favorite') {
      generateFavoriteQuote(
        readingChapter,
        selectedText,
        getBookByName,
        saveFavoriteQuote,
      )
        .then(_ => {
          alert('Citat je uspješno spremljen');
        })
        .catch(e => {
          console.log(e);
        });
    } else if (key === 'note') {
      setNoteEditModalVisible(true);
    }
  };

  return (
    <>
      <div style={{paddingLeft: 40, paddingRight: 40}}>
        {!customHtmlContent && !isFirstChapter && (
          <UIPressable
            onPress={openPreviousChapter}
            style={{position: 'absolute', top: 300, left: 10}}>
            <Icon name="arrow-left" size={20} />
          </UIPressable>
        )}

        <div
          dangerouslySetInnerHTML={{__html: htmlContent}}
          style={{width: '100%'}}
          ref={slideRef}
        />
        {!customHtmlContent && (
          <UIPressable
            onPress={openNextChapter}
            style={{position: 'absolute', top: 300, right: 10}}>
            <Icon name="arrow-right" size={20} />
          </UIPressable>
        )}
      </div>
      <FootnoteModal
        modalVisible={footNoteModalVisible}
        setModalVisible={setFootNoteModalVisible}
        footnoteContent={footnote}
      />
      <EditNoteModal
        modalVisible={noteEditModalVisible}
        setModalVisible={setNoteEditModalVisible}
        chapter={readingChapter}
      />
      <ActionPickerModal
        modalVisible={actionPickerModalVisible}
        setModalVisible={setActionPickerModalVisible}
        setSelectedText={setSelectedText}
        selectedText={selectedText}
        handleSelectionEvent={handleSelectionEvent}
      />
    </>
  );
};

export default ReadingView;
