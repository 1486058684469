import React, {useContext, useEffect, useState} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import Icon from '../ui-components/Icon';
import {
  chapterId,
  label,
  note,
  positionInBook,
  timestamp,
} from '../../constants/stringsAndFields';
import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import DataContext from '../../context/dataContext';
import {convertTimeStampToString} from '../../utils/dateTime';
import {UIPressable} from '../ui-components/UIPressable';

const NoteItem = ({noteItem, onDeleteNote, onNoteClick}) => {
  const {getChapter} = useContext(DataContext);
  const [chapter, setChapter] = useState(null);
  const {theme} = useTheme();
  const global = globalStyle(theme);

  const handleDeleteClick = async e => {
    await onDeleteNote(noteItem);
  };

  const handleNoteClick = async _ => {
    onNoteClick(noteItem);
  };

  useEffect(() => {
    getChapter(noteItem[chapterId]).then(chapter => setChapter(chapter));
    console.log('noteItem[timestamp]: ' + noteItem[timestamp]);
  }, []);

  return (
    <TouchableOpacity style={global.listItem} onPress={handleNoteClick}>
      <View style={global.listItemTextContainer}>
        <Text style={global.listItemTitle}>
          {noteItem[label]} {chapter?.[positionInBook]}
        </Text>
        <Text style={global.listItemContent}>{noteItem[note]}</Text>
        <Text style={global.listItemDate}>
          {convertTimeStampToString(noteItem[timestamp])}
        </Text>
      </View>
      <UIPressable onPress={handleDeleteClick} style={{padding: 5}}>
        <Icon name="trash" style={global.listItemIcon} />
      </UIPressable>
    </TouchableOpacity>
  );
};

export default NoteItem;
