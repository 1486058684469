import {
  chapterId,
  readingEntryId,
  verse_number,
  id,
  title,
  positionInBook,
  book_title,
} from '../constants/stringsAndFields';

export const isNotEmpty = list => list && list.length > 0;
export const isEmpty = list => list && list.length === 0;

export const chunks = (array, size) => {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, size));
  }
  return results;
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const checkValidArray = (filter, filterData) => {
  return Array.isArray(filter) &&
    filter.length == 2 &&
    Array.isArray(filterData) &&
    filterData.length == 2
    ? true
    : false;
};

export const checkNotEmptyArray = arr => {
  return Array.isArray(arr) && arr.length > 0 ? true : false;
};

export const getIndexByChapterId = (chapters, chapterIdVal) => {
  return chapters.findIndex(item => item[chapterId] === chapterIdVal);
};

export const getIndexByReadingEntryId = (readings, readingEntryIdVal) => {
  return readings.findIndex(
    item => item[readingEntryId] === readingEntryIdVal[readingEntryId],
  );
};

export const isNotNullNumber = index => {
  return !Object.is(index, null) && index >= 0;
};

export const isNumber = value => typeof value === 'number';

export const extendMissingVerses = (verses, chapter, book) => {
  const extendedVerses = [];
  if (verses && verses.length) {
    const lastVerse = verses[verses.length - 1];
    const lastVerseNumber = lastVerse[verse_number];
    if (lastVerseNumber !== verses.length) {
      let index = 1;
      for (const verse of verses) {
        if (verse[verse_number] !== index) {
          extendedVerses.push({
            [id]: `${book[title]}${chapter[positionInBook]}_${index}`,
            [verse_number]: index,
            ['chapter']: chapter[positionInBook],
            [book_title]: book[title],
          });
          index++;
        }
        extendedVerses.push(verse);
        index++;
      }
    }
  }
  if (extendedVerses.length) {
    return extendedVerses;
  } else {
    return verses;
  }
};
