import {useTheme} from '../../theme/ThemeContext';
import globalStyle from '../../theme/globalStyle';
import {Text, TouchableOpacity, View} from 'react-native';
import {verse_number} from '../../constants/stringsAndFields';

const VerseGridItem = ({verse, onItemPress}) => {
  const {theme} = useTheme();
  const global = globalStyle(theme);

  return (
    <View style={global.gridItemWrapper}>
      <TouchableOpacity
        style={global.gridItem}
        onPress={() => onItemPress(verse)}>
        <Text style={global.textMain}>{verse[verse_number]}</Text>
      </TouchableOpacity>
    </View>
  );
};
export default VerseGridItem;
