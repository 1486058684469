import React, {useState} from 'react';
import {TextInput, View, StyleSheet} from 'react-native';
import {useTheme} from '../../theme/ThemeContext';

const UITextInput = ({
  value,
  onChangeText,
  placeholder,
  keyboardType,
  autoComplete,
  autoCorrect,
  multiline,
  style,
  containerStyle,
}) => {
  const {theme} = useTheme();
  const styles = getStyles(theme);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <View style={containerStyle}>
      <TextInput
        style={[
          styles.input,
          isFocused && styles.inputFocused,
          style,
          multiline === true && styles.textArea,
        ]}
        value={value}
        onChangeText={onChangeText}
        placeholder={placeholder}
        keyboardType={keyboardType}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        multiline={multiline}
        textAlignVertical="top"
        onFocus={handleFocus}
        onBlur={handleBlur}
        selectionColor={theme.colors.primary}
      />
    </View>
  );
};

UITextInput.defaultProps = {
  keyboardType: 'default',
  autoComplete: 'off',
  autoCorrect: false,
  multiline: false,
};

const getStyles = theme => {
  return StyleSheet.create({
    input: {
      backgroundColor: theme.colors.navBackground,
      height: 40,
      borderWidth: 1,
      borderColor: theme.colors.border,
      borderRadius: 8,
      padding: 10,
      fontSize: 14,
      fontFamily: theme.fontFamily,
      color: theme.colors.text,
    },

    inputFocused: {
      borderColor: theme.colors.primary,
    },

    textArea: {
      height: 'auto',
      minHeight: 100,
    },
  });
};

export default UITextInput;
