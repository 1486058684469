export const initEvents = `
<script>   
  console.log("initEvents");
  function NativeInterface (e,t){
    console.log(e);
    console.log(t);
    alert("Name Cannot be empty!"); 
  }
  !(function () {
  function e() {
    for (
      var e = document.querySelectorAll('.footnote'), t = 0;
      t < e.length;
      t++
    )
      e[t].addEventListener('click', function (e) {
        var t;
        e.currentTarget && (t = e.currentTarget.getAttribute('id')),
          (t =
            t ||
            e.target.getAttribute('id') ||
            e.target.parentNode.getAttribute('id')),
          interfaceExecuteCall('activateFootnote', t);
      });
    for (
      var n = document.querySelectorAll('.front-arrow'), t = 0;
      t < n.length;
      t++
    )
      n[t].addEventListener('click', function () {
        interfaceExecuteCall('nextChapter');
      });
  }
  (interfaceExecuteCall = function (e, t) {
    NativeInterface(e, t);
  }),
    document.addEventListener('DOMContentLoaded', function (t) {
      console.log("Loaded script");
      e();
    });
})();
</script> `;
