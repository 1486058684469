import {useIsFocused} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import FavoriteQuoteList from '../components/favorite-quote/FavoriteQuoteList';
import DataContext from '../context/dataContext';

const FavoritesScreen = ({navigation, route}) => {
  const {getFavoriteQuotes, deleteFavoriteQuote} =
    useContext(DataContext);
  const [favoriteQuotes, setFavoriteQuotes] = useState([]);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      refreshQuotes().catch(console.error);
    }
  }, [isFocused]);

  const refreshQuotes = async () => {
    const quotesDb = await getFavoriteQuotes();
    if (quotesDb) {
      setFavoriteQuotes([...quotesDb]);
    }
  };

  const onDeleteQuote = async quote => {
    await deleteFavoriteQuote(quote);
    await refreshQuotes();
  };

  return (
    <FavoriteQuoteList quotes={favoriteQuotes} onDeleteQuote={onDeleteQuote} />
  );
};

export default FavoritesScreen;
