import React from 'react';
import {FlatList} from 'react-native';
import {id} from '../../constants/stringsAndFields';
import SearchVerseItem from './SearchVerseItem';
import {UIListEmpty} from '../ui-components/UIListEmpty';
import {IS_WEB} from '../../utils/platform';

const SearchVersesList = ({verses, onVerseClick, searchQuery}) => {
  const render = ({item}) => (
    <SearchVerseItem verseItem={item} onVerseClick={onVerseClick} />
  );

  const listEmpty = () => {
    const icon = searchQuery ? 'exclamation' : 'note';
    const text = searchQuery
      ? 'Nema rezultata pretrage!'
      : 'Upiši upit za pretragu';
    return <UIListEmpty icon={icon} text={text} />;
  };

  return (
    <FlatList
      data={verses}
      renderItem={render}
      keyExtractor={item => item[id]}
      ListEmptyComponent={listEmpty}
      contentContainerStyle={IS_WEB && {height: 'calc(100vh - 192px)'}}
    />
  );
};

export default SearchVersesList;
