import {
  INTEGER,
  TEXT,
  additionalBook,
  audioBookName,
  audioDownloaded,
  label,
  sequence,
  testament,
  title,
  translated,
} from '../constants/stringsAndFields';
import {isNotEmpty} from '../utils/arrays';
import {
  checkTableExists,
  deleteData,
  executeTransaction,
  insertData,
  selectData,
  updateData,
} from './common';
import {runSqlCmd} from './sqlOperations';

export const BOOK_TABLE = 'Book';
//CREATE TABLE `Book`(`title` TEXT,`label` TEXT,`sequence` INTEGER, `testament` INTEGER, `translated` INTEGER, PRIMARY KEY(`title`));
const keys = [
  title,
  label,
  sequence,
  testament,
  additionalBook,
  audioBookName,
  translated,
  audioDownloaded,
];

// TODO: add index which is used for query
export const createBookTable = async tx => {
  await runSqlCmd(tx, `DROP TABLE IF EXISTS ${BOOK_TABLE};`);
  await runSqlCmd(
    tx,
    `CREATE TABLE IF NOT EXISTS ${BOOK_TABLE}(
      ${title} ${TEXT} PRIMARY KEY NOT NULL,
      ${label} ${TEXT},
      ${sequence} ${INTEGER},
      ${testament} ${TEXT},
      ${additionalBook} ${INTEGER} DEFAULT 0,
      ${audioBookName} ${TEXT},
      ${translated} ${INTEGER} DEFAULT 0,
      ${audioDownloaded} ${INTEGER} DEFAULT 0
      );`,
  );
};

export const reCreateBookTableAndData = async (tx, cmds) => {
  await createBookTable(tx);
  await executeTransaction(tx, cmds);
};

export const insertBook = async (tx, book) => {
  await insertData(tx, BOOK_TABLE, keys, book);
};

export const deleteAllBooks = async tx => {
  await deleteData(tx, BOOK_TABLE);
};

export const deleteBook = async (tx, book) => {
  await deleteData(tx, BOOK_TABLE, book[title]);
};

export const updateBook = async (tx, book) => {
  await updateData(tx, BOOK_TABLE, book, title);
};

export const getBooks = async tx => {
  const books = await selectData(tx, BOOK_TABLE, keys);
  return isNotEmpty(books) ? books : [];
};

export const getBook = async (tx, dataId) => {
  const books = await selectData(
    tx,
    BOOK_TABLE,
    keys,
    title,
    dataId,
    [],
    null,
    'LIMIT 1',
  );
  return isNotEmpty(books) ? books[0] : null;
};

export const checkBookTable = async tx => {
  return await checkTableExists(tx, BOOK_TABLE);
};
